import { AfterViewInit, Component, OnInit } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { slideInAnimation } from './transition/route-animation';
// import { authCodeFlowConfig } from './Config/authCodeFlowConfig';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import * as jwt_decode from 'jwt-decode';
import { Router } from '@angular/router';

declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [ slideInAnimation ] //importamos la constante de animacion
})
export class AppComponent implements OnInit, AfterViewInit {
  title = 'Ekiluz';

  respuestaToken:any;
  token:string;
  // existeToken:boolean;
  constructor(private oAuthService: OAuthService) { }

 


  public ngOnInit(): void {
    // this.configureSingleSignOn();
    this.usuarioAutenticado();

  }

  ngAfterViewInit():void{
    window.addEventListener('scroll', this.scroll, true);

  }

  usuarioAutenticado(){
    this.token = sessionStorage.getItem('userToken');
    // if(this.token !== "" && this.token!== null && this.token !== undefined){
    //  this.existeToken = true; 
    // }else{
    //   this.existeToken = false;
    // }
    // console.log(this.existeToken);

  }
  
  scroll = (event): void => {

    let posicionamientoScroll = $("html, body").scrollTop();
    // let evento = event.srcElement || event.target;
    if (posicionamientoScroll && posicionamientoScroll > 0) {
      $('#arrowUp').slideDown(200);
    } else {
      $('#arrowUp').slideUp(200);
    }
  };

  scrollToHeight0(){
    $('body, html').animate({
      scrollTop: '0px'
    }, 300);
  }

}



