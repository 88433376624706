import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Contrato } from '../Models/contrato.model';
import {environment} from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ContratoService {


  urlApi:any = environment.url + "/contracts";

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(private http: HttpClient) { }

  getContratos(): Observable<Contrato[]>{
    return this.http.get<Contrato[]>(this.urlApi);
  }

  descargarContrato(urlContrato:string): Observable<any>{
    return this.http.get<Blob>(this.urlApi + "/download/" + urlContrato, {responseType:'blob' as 'json'});
  }

  descargarContratoDesdeMail(contractName, userId): Observable<any>{
    let parameters = new HttpParams().append('contractName',contractName).append('userId',userId);
    return this.http.get<Blob>(this.urlApi + "/downloadMail?", {responseType:'blob' as 'json', params:parameters});
  }

}


