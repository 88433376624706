import { EventEmitter, Injectable } from "@angular/core";


@Injectable({
  providedIn: 'root'
})
export class HeaderNavService {

  mostrarMenu$ = new EventEmitter<any>();

  // mostrarDirUsuario$ = new EventEmitter<boolean>();

  mostrarUsuarioLogueado$ = new EventEmitter<boolean>();

  informacionUsuarioLogueado$ = new EventEmitter<string>();

  mostrarHeader$ = new EventEmitter<boolean>();


  constructor() { }

  visibilidadMenu(flag: boolean) {
    this.mostrarMenu$.emit(flag)
  }

  // visibilidadDirUsuario(flag:boolean){
  //   this.mostrarDirUsuario$.emit(flag);
  // }

  // valorNombreUsuario(usuario:string){
  //   this.informacionUsuarioLogueado$.emit(usuario);
  // }
  
  visibilidadUsuarioLogueado(flag:boolean){
    this.mostrarUsuarioLogueado$.emit(flag);
  }

  visibilidadHeader(flag:boolean){
    this.mostrarHeader$.emit(flag);
  }

}





