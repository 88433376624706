<div class="row white  col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 noPadding " id="principalReset">
  <div class="row navBarHeight col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 noPaddingMarginRow">
    <div class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 alignHeader" id="containerLogoLogin">
      <img src="assets/img/logo-ekiluz-energia.svg" id="logoEkiluzEnergiaReset" class="separationTop"
        (click)="redirigirHome()">
    </div>
  </div>

  <div class=" row  col-9 col-sm-9 col-md-9 col-lg-9 col-xl-9 noPaddingMarginRow" style="justify-content: right;" id="contactoOlvidoPass">
    <div class="row col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12 noPaddingMarginRow" style="padding-top:25px;"
      id="maxAnchoOlvido">
      <div class="col-12 col-md-10 col-sm-10 col-lg-10 col-xl-10" style="text-align: -webkit-right;">
        <span style="font-size: .85rem;">{{"LOGIN.PROBLEMASACCESO" | translate}}</span>
        <a style="font-size:.85rem;" id="contactanos" href="tel:+34900293741">{{"LOGIN.CONTACTANOS" | translate}}</a>
      </div>

      <div class="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2 noPaddingMarginRow" id="selectorIdiomaContainer">
        <div ngbDropdown>
          <button class="btn selectorIdioma" id="dropdownBasicIdioma"
            ngbDropdownToggle>
            <div id="contenedorImgTextoLogin">
              <span style="font-size:.85rem;" id="selectorLogin">{{'SELECTOR.TITLE' | translate }}</span>
            </div>
          </button>
          <div ngbDropdownMenu aria-labelledby="dropdownBasicIdioma">
            <button ngbDropdownItem (click)="utilsService.actualizarIdiomaNoLogueado('es')"
              [ngClass]="translateService.currentLang == 'es' ? 'idiomaSeleccionado' : ''">{{'SELECTOR.SPANISH' | translate
              }} <i class="fa fa-check" *ngIf="translateService.currentLang === 'es'"></i></button>
            <button ngbDropdownItem (click)="utilsService.actualizarIdiomaNoLogueado('eu')"
              [ngClass]="translateService.currentLang == 'eu' ? 'idiomaSeleccionado' : ''">{{'SELECTOR.EUSKERA' | translate
              }} <i class="fa fa-check" *ngIf="translateService.currentLang === 'eu'"></i></button>
          </div>
        </div>
      </div>
    </div>
  </div>










  <div class=" col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12  menuBackground ">
    <div class="row col-10 col-sm-10 col-md-5 col-lg-5 col-xl-5 mx-auto borderBottom" id="divPaddingOlvidoPass"
      style="background-color: white;padding: 87px 87px 70px 87px;margin-top:2em;margin-bottom: 1em;">
      <div class="col-12 col-sm-12 col-xl-12 col-lg-12 col-md-12 noPaddingMarginRow" style="padding:0px;">
        <h4 style="color:black;">{{"LOGIN.BIENVENIDO" | translate}}</h4>
      </div>
      <h5 style="color:black;">{{"RECUPERARPASSWORD.CAMBIOPASSWORD" | translate}}</h5>
      <div style="margin-top:.75em;width: 100%;">
        <div class="form-group largeForm">
          <mat-form-field class="anchoForm">
            <mat-label>{{"RECUPERARPASSWORD.NUEVAPASSWORD" | translate}}</mat-label>
            <input type="password" id="passwordReset" [(ngModel)]="password" matInput
              [placeholder]="'RECUPERARPASSWORD.NUEVAPASSWORD' | translate">
            <span class="loginIcon" (click)="cambiarIcono($event,'passwordReset','iconEyeReset')"><i
                class="fa fa-eye-slash" id="iconEyeReset"></i></span>
          </mat-form-field>
        </div>



        <div class="form-group largeForm">
          <mat-form-field class="anchoForm">
            <mat-label>{{"RECUPERARPASSWORD.CONFIRMARPASSWORD" | translate}}</mat-label>
            <input type="password" id="passwordResetRepeat" [(ngModel)]="repeticionPassword" matInput
              [placeholder]="'RECUPERARPASSWORD.CONFIRMARPASSWORD' | translate">
            <span class="loginIcon" (click)="cambiarIcono($event,'passwordResetRepeat','iconEyeReset2')"><i
                class="fa fa-eye-slash" id="iconEyeReset2"></i></span>
          </mat-form-field>
        </div>
      </div>

      <small class="passOlvidada" (click)="abrirModal()">{{"RECUPERARPASSWORD.COMOPASSWORD" | translate}}</small>
      <button type="button" class="btn btn-warning btn-lg right bigBtn"
        style="font-weight: bold;margin-top: .75em;margin-bottom: .75em;"
        (click)="cambiarPassword()">{{"RECUPERARPASSWORD.MODIFICARPASSWORD" | translate}}</button>
      <small id="error" *ngIf="error">{{textoError}}</small>
    </div>
  </div>
</div>