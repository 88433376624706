import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
    providedIn: 'root'
  })
  export class UtilsService {
    constructor(private router:Router, private translateService: TranslateService) {}

    cargarIdiomaNoLogueado(idioma: string) {
        switch (idioma) {
            case 'es':
            case 'eu':
                this.translateService.setDefaultLang(idioma)
                this.translateService.use(idioma)
                break
            default: // si el idioma no esta contemplado por defecto se pone español
                var url = this.router.url.split('?') // separa la ruta de los parametros
                if (url.length > 1) {
                    var aux = url[1].split('&') // divide cada parametro
                    var params = { language: 'es' }
                    for (let index = 0; index < aux.length; index++) {
                        let param = aux[index].split('=') // divide el parametro en nombre y valor
                        if(param[0] != 'language') {
                            params[param[0]] = param[1]
                        }
                    }
                    this.router.navigate([url[0]], { queryParams: params })
                } else {
                    this.router.navigate([url[0]], { queryParams: { language: 'es' } })
                }
                
        }
    }

    actualizarIdiomaNoLogueado(idioma: string) {
        var url = this.router.url.split('?') // separa la ruta de los parametros
        if (url.length > 1) {
            var aux = url[1].split('&') // divide cada parametro
            var params = { language: idioma }
            for (let index = 0; index < aux.length; index++) {
                let param = aux[index].split('=') // divide el parametro en nombre y valor
                if(param[0] != 'language') {
                    params[param[0]] = param[1]
                }
            }
            this.router.navigate([url[0]], { queryParams: params })
        } else {
            this.router.navigate([url[0]], { queryParams: { language: 'es' } })
        }
    }

    actualizarIdioma(idioma: string, def: boolean) {
        switch (idioma) {
            case 'Español':
                if(def) this.translateService.setDefaultLang('es')
                this.translateService.use('es')
                break
            case 'Euskera':
                if(def) this.translateService.setDefaultLang('eu')
                this.translateService.use('eu')
                break
            case 'es':
            case 'eu':
                if(def) this.translateService.setDefaultLang(idioma)
                this.translateService.use(idioma)
                break
            default: // si el idioma no esta contemplado por defecto se pone español
            if(def) this.translateService.setDefaultLang('es')
            this.translateService.use('es')
        }
    }

    isNotNullOrNotEmptyOrNotUndefined(valor: any): boolean {
        let flag = false;
        if (valor != undefined && valor != null && valor != "") {
            flag = true;
        }
        return flag;
     }

    isNullOrEmptyOrUndefined(valor: any): boolean {
        let flag = false;
        if (valor == undefined || valor == null || valor == "") {
            flag = true;
        }
        return flag;
    }

    comprobarNumero(numero: any): boolean {
        let flag;
        if (isNaN(numero)) { //es una cadena
            flag = false;
        } else {
            flag = true; //es un numero
        }
        return flag;
    }

    esNumeroDeTelefono(numero: any): boolean {
        let flag = false;
        if (numero.length == 9) {
            flag = true;
        }
        return flag;
    }

    esCorreoElectronico(correo: string): boolean {
        let flag = false;
        let regexEmail = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
        if (regexEmail.test(correo)) {
            flag = true;
        }
        return flag;
    }

    esDniValido(dni: string): boolean {
        let flag = false;
        let regexDni = /^[0-9]{8,8}[A-Za-z]$/;
        if (regexDni.test(dni)) {
            flag = true;
        }
        return flag;
    }

    requisitosSeguridadPassword(password:string):boolean{
        /*
        *al menos 1 min
        *al menos 1 mayus
        *al menos 1 número
        *al menos 1 simbolo especial
        */
        let flag = false;
        let regexPass = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[¡!$?&#@\/\()=¿?*\[\]\;,:._<>+-]).{8,}$/
        if (regexPass.test(password)){
            flag=true;
        }
        return flag;
    }

    existeUsuarioLogueado(){
        let token =  sessionStorage.getItem('userToken');
        if (!token){ 
            this.router.navigate(['login']);
        } 
        // else {
        //     this.router.navigate(['facturas']);
        // }
    }

    existeToken(): boolean{
        let existe:boolean = true;
        let token =  sessionStorage.getItem('userToken');
        if (token == null || token == undefined || token == ''){
            existe = false;
    }
    return existe;
}

    generarPDF(respuesta: any, nombreFactura: string, startDate: string, endDate: string) {
        let binaryData = [];
        let nombreFichero;
        binaryData.push(respuesta);
        let downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: 'application/pdf' }));
        //nombreFichero
        if (startDate != '' && endDate != '') {
            nombreFichero = nombreFactura + "_" + startDate + "_" + endDate;
        } else {
            nombreFichero = nombreFactura;
        }
        //End nombreFichero
        downloadLink.setAttribute('download', nombreFichero);
        document.body.appendChild(downloadLink);
        downloadLink.click();
    }

    generarPDFCrosscheck(respuesta: any, nombreFactura: string) {
        let binaryData = [];
        let nombreFichero;
        binaryData.push(respuesta);
        let downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: 'application/pdf' }));
        //nombreFichero
        nombreFichero = nombreFactura;
        //End nombreFichero
        downloadLink.setAttribute('download', nombreFichero);
        document.body.appendChild(downloadLink);
        downloadLink.click();
    }

    ordenarFacturasPorFecha(array: Array<any>) {
        array = array.sort((a, b) => new Date(b.startDate).getTime() - new Date(a.startDate).getTime());
        return array;
      }
  
}
