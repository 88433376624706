import { AfterViewInit, Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { HeaderNavService } from '../../Services/headerNavService';
import {Router} from '@angular/router';
import {OAuthService} from 'angular-oauth2-oidc';
import { LoginService } from 'src/app/Services/login.service';
import { UsuarioService } from 'src/app/Services/usuario.service';
import { HttpResponse } from '@angular/common/http';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UtilsService } from 'src/app/utils/utilsService';
import { AppComponent } from 'src/app/app.component';
import { TranslateService } from '@ngx-translate/core';



declare var $;

@Component({
  selector: 'headerNav',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit,AfterViewInit {

 
  mostrarNavegacion:boolean = true;
  mostrarDireccionUsuario:boolean = true;
  mostrarUsuarioLogueado:boolean = true;
  nombreUsuario:string;
  apellidosUsuario:string;
  mostrarHeader: boolean = false;


  constructor(private headerNavService: HeaderNavService, private router:Router,private oAuthService:OAuthService,
    private loginService:LoginService, private userService:UsuarioService, public dialog: MatDialog, public translateService: TranslateService, public utilsService: UtilsService) { }

  ngOnInit(): void {

    const token: string = sessionStorage.getItem('userToken');

    if (token){ //si el usuario esta logueado mostramos el header por si hace recarga de la pagina, a parte del event emmiter
      this.mostrarHeader = true;
      this.obtenerDetallesUsuario();
    }

    this.headerNavService.mostrarHeader$.subscribe(filtroServiceBoolean => {
      this.mostrarHeader = filtroServiceBoolean;
      const token: string = sessionStorage.getItem('userToken');
      if (token){ //si existe el token porque se ha grabado en el sessionStrg lo cojo de ahí
        this.obtenerDetallesUsuario();
      } 
    });

    
  
    this.headerNavService.mostrarMenu$.subscribe(filtroServiceBoolean => {
      this.mostrarNavegacion = filtroServiceBoolean;
    });

    // this.headerNavService.mostrarDirUsuario$.subscribe(filtroServiceBoolean => {
    //   this.mostrarDireccionUsuario = filtroServiceBoolean;
    // });

    this.headerNavService.mostrarUsuarioLogueado$.subscribe(filtroServiceBoolean => {
      this.mostrarUsuarioLogueado = filtroServiceBoolean;
    });
    

    this.router.events.subscribe((event) => {
      if (event){
        // if (event['url'] == "/principal"){
          this.ngAfterViewInit();
      }
    });
  }

  rutaVariableLogo(){
    const token: string = sessionStorage.getItem('userToken');
    if (token){ //Si el usuario esta logueado, al clickar el logo redirige a facturas y hay que remarcarlo como nav tab activa
      $(".navbar").find(".active").removeClass("active"); //eliminar clase activa por defecto
      $(".navbar").find("li a").css("line-height","43px"); //cambiar lineHeight al elemento que ya no esta activo
      $("#facturas").addClass("active"); //añadir clase activa al elemento seleccionado
      $(".navbar").find(".active").children().css("line-height","35px"); //restar altura de texto para que no se descuadre con el border-top superior
      this.router.navigate(['facturas']);
    }else{
      this.router.navigate(['login']);
      window.location.reload();
    }
  }

  
  ngAfterViewInit(): void{
    $(".navbar .nav-item").on("click", function(){
      $(".navbar").find(".active").removeClass("active"); //eliminar clase activa por defecto
      $(".navbar").find("li a").css("line-height","43px"); //cambiar lineHeight al elemento que ya no esta activo
      $(this).addClass("active"); //añadir clase activa al elemento seleccionado
      $(".navbar").find(".active").children().css("line-height","35px"); //restar altura de texto para que no se descuadre con el border-top superior
   });
  }



  logOut(){
      this.abrirModal();
      // this.loginService.setLogoutSession();
      // this.mostrarHeader = false;
      // this.router.navigate(['login']);
  }

  obtenerDetallesUsuario(){
    this.userService.getUsuarioDetails().subscribe(
      (response: any) => {
       if (response && response.records && response.records[0]){
        this.nombreUsuario = response.records[0].name;
        this.apellidosUsuario = response.records[0].surname;
        this.utilsService.actualizarIdioma(response.records[0].language, true)
       }
      },
      err => {
        console.error("Ocurrió un error: "+err.message);
      }
    );
  }

  

  abrirModal() {
    const dialogRef = this.dialog.open(dialogModalLogOut, {
      data: {instanciaHeader:this}
    });

    dialogRef.afterOpened().subscribe(result => {
      console.log(`Dialog result: ${result}`); 
      //GESTIONES DEL MODAL DESPUES DE SU APERTURA
      /*Impedimos que se pueda cerrar el modal y sea fija la navegación al area cliente*/
      if (dialogRef.componentInstance.data.info == "registroUsuario"){
        dialogRef.disableClose = true;
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`); 
      //result == true click en continuar o en la x || undefined = click fuera del modal
      //GESTIONES DEL MODAL DESPUES DE SU CIERRE
    });
  }
  
  
  
  ngOnDestroy():void{
    this.headerNavService.mostrarMenu$.unsubscribe();
    // this.headerNavService.mostrarDirUsuario$.unsubscribe();
    this.headerNavService.mostrarUsuarioLogueado$.unsubscribe();
  }

}


/*SELECTOR PARA LA CLASE MODAL*/

@Component({
  selector: 'modalLogOut',
  templateUrl: './modalLogOut.html',
  styleUrls: ['./modalLogOut.scss'],
  encapsulation: ViewEncapsulation.None
  //quitamos la encapsulación para dejar el resto de modales obtengan su conf de styles.css generico y sobreescribir las propiedades desde este componente
})
export class dialogModalLogOut {

 

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private utilsService: UtilsService, private loginService:LoginService, private router:Router ) {}


  ngOnInit(): void {
    // if (this.data && this.data.info){

  }


  confirmarLogOut() {
    if (this.data && this.data.instanciaHeader) {
        this.loginService.setLogoutSession();
        this.data.instanciaHeader.mostrarHeader = false;
      // this.mostrarHeader = false;
       this.router.navigate(['login']);
    }

  }
}
