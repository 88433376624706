import { EventEmitter, Injectable } from "@angular/core";


@Injectable({
  providedIn: 'root'
})
export class FooterService {

  mostrarFooter$ = new EventEmitter<boolean>();

  constructor() { }

  visibilidadFooter(flag:boolean){
    this.mostrarFooter$.emit(flag);
  }

}





