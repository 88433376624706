
<div class="row white footerHeight col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 noPaddingMarginRow backgroundColor" id="containerFooter" *ngIf="mostrarFooter">
    <div class="row col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 noPaddingMarginRow "
        style="margin-top:0.25em;position:relative;">
        <div class="col-8 col-sm-8 col-md-8 col-lg-8 col-xl-8" style="padding-left:0;">
            <img src="assets/img/logo_blanco.png" style="width: 95px;">
        </div>
        <div class="col-4 col-md-4  col-sm-4 col-lg-4 col-xl-4" style="padding:0;top:10px;">
            <ul id="footerList" style="margin-top:0.5em;float:right;margin-right:5px;line-height: 15px;"> 
                <li><a target="_blank" href="https://ekiluzenergia.com/sobre-nosotros/">{{'FOOTER.NOSOTROS' | translate}}</a></li>
                <!-- <li><a target="_blank" href="https://ekiluzenergia.com/aviso-legal-y-terminos-de-uso/">Condiciones legales</a></li>
                <li><a target="_blank" href="https://ekiluzenergia.com/politica-de-privacidad/">Política de privacidad</a></li>
                <li><a target="_blank" href="https://ekiluzenergia.com/politica-de-cookies/">Política de cookies</a></li>
                <li><a target="_blank" href="https://ekiluzenergia.com/faqs/">Preguntas frecuentes</a></li> -->
            </ul>
        </div>
    </div>

 
    <div class="row col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="height: 12px;padding-left: 33px;bottom: 16px;">
        <hr style="height:0.05px;background-color: white;width:100%;">
    </div>

    <div class="row col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="padding-right: 0;">
        <div class="col-md-3 col-sm-2 col-lg-3 col-xl-3 col-2">
            <span style="margin-left:2px;" id="energia">{{fechaActual}} &#169; {{'FOOTER.EKILUZ' | translate}}</span>
        </div>
        <div class="col-md-9 col-sm-10 col-md-9 col-lg-9 col-xl-9 col-10" style="padding:0;">
            <!-- <span style="float:right;">clientes@ekiluzenergia.com</span> -->
            <!-- <a style="float:right;line-height: 25px;" class="borderHover"
                href="mailto:clientes@ekiluzenergia.com?subject=Dudas y consultas área de clientes">clientes@ekiluzenergia.com</a> -->
                <ul id="footerListFooter" style="float:right;margin-right:5px;margin-bottom: .5em;"> 
                    <li><a target="_blank" href="https://ekiluzenergia.com/aviso-legal-y-terminos-de-uso/">{{'FOOTER.CONDICIONES' | translate}}</a></li>
                    <li><a target="_blank" href="https://ekiluzenergia.com/politica-de-privacidad/">{{'FOOTER.PRIVACIDAD' | translate}}</a></li>
                    <li><a target="_blank" href="https://ekiluzenergia.com/politica-de-cookies/">{{'FOOTER.COOKIES' | translate}}</a></li>
                    <li><a target="_blank" href="https://ekiluzenergia.com/faqs/">{{'FOOTER.PREGUNTAS' | translate}}</a></li> 
                </ul>
        </div>
    </div>
    
</div>