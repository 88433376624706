import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { Usuario } from 'src/app/Models/usuario.model';
import { HeaderNavService } from 'src/app/Services/headerNavService';
import { UsuarioService } from 'src/app/Services/usuario.service';
import { UtilsService } from 'src/app/utils/utilsService';
import { dialogModal } from '../contacto/contacto.component';

declare var $;
@Component({
  selector: 'olvidoPass',
  templateUrl: './olvido-pass.component.html',
  styleUrls: ['./olvido-pass.component.scss']
})
export class OlvidoPassComponent implements OnInit {


  password: string;
  repeticionPassword: string;
  error: boolean = false;
  textoError: string;

  mensajeToast1: string
  mensajeToast2: string
  tokenRecibidoMail: string;

  constructor(private router: Router, public utilsService: UtilsService, private spinner: NgxSpinnerService, private userService: UsuarioService,
    public dialog: MatDialog, private route: ActivatedRoute, private toastr: ToastrService, private headerNavService: HeaderNavService, public translateService: TranslateService) { }

  ngOnInit(): void {

    this.headerNavService.visibilidadHeader(false);

    this.route.queryParams.subscribe(params => {
      this.utilsService.cargarIdiomaNoLogueado(params.language)
      
      if (params && params.token && params.token != "") {
        this.tokenRecibidoMail = params.token;
      }

      if (!this.tokenRecibidoMail) { //si has accedido mediante la url y no desde el mail, redirección a login
        this.redirigirHome();
      }
    });
  }

  redirigirHome() {
    this.router.navigate(['login']);
    // window.location.reload();
  }


  cambiarIcono(evento, inputId, iconId) {
    if (evento) {
      let tipoInput = $("#" + inputId).attr("type");
      if (tipoInput == "password") {
        $("#" + inputId).prop("type", "text");
        $("#" + iconId).attr("class", "fa fa-eye");
      } else {
        $("#" + inputId).prop("type", "password");
        $("#" + iconId).attr("class", "fa fa-eye-slash");
      }
    }
  }

  cambiarPassword() {
    this.error = false;

    if (this.utilsService.isNotNullOrNotEmptyOrNotUndefined(this.password) && this.utilsService.isNotNullOrNotEmptyOrNotUndefined(this.repeticionPassword)) {
      if (this.password !== this.repeticionPassword) {
        // setTimeout(() => {
        //   this.spinner.hide();
        this.error = true;
        this.password = '';
        this.repeticionPassword = '';
        // this.toastr.error('Por favor, revise', 'Existen campos sin completar');
        this.translateService.stream("MENSAJES.DATOSINCORRECTOS").subscribe(resul => {
          this.textoError = resul
        });
        // }, 1500);
      } else {
        if (this.utilsService.requisitosSeguridadPassword(this.password)) {
          if (this.tokenRecibidoMail) {
            this.spinner.show();
            // let userToResetPassword = {
            //   password: this.password,
            //   repPassword: this.repeticionPassword,
            //   token: this.tokenRecibidoMail
            // }

            let userToResetPassword: Usuario = { id: this.tokenRecibidoMail, password: this.password, oldPassword: this.repeticionPassword, language: this.translateService.currentLang };
            this.userService.resetPassword(userToResetPassword).subscribe(
              (response: any) => {
                setTimeout(() => {
                  this.spinner.hide();
                  this.translateService.stream("MENSAJES.PASSWORDACTUALIZADA").subscribe(resul => {
                    this.mensajeToast1 = resul
                  });
                  this.toastr.success('', this.mensajeToast1, {
                    disableTimeOut: true
                  });
                  this.translateService.stream("MENSAJES.DESDENUEVAPASSWORD").subscribe(resul => {
                    this.mensajeToast2 = resul
                  });
                  this.toastr.success('', this.mensajeToast2, {
                    disableTimeOut: true
                  });
                  this.password = '';
                  this.repeticionPassword = '';
                  this.redirigirHome();
                }, 500);
              },
              err => {
                console.error("Ocurrió un error: " + err.message);
                let errorCode = err.status;
                // let mensaje;
                switch (errorCode) {
                  case 404:
                    this.translateService.stream("MENSAJES.DATOSINCORRECTOS").subscribe(resul => {
                      this.textoError = resul
                      // mensaje = resul
                    }); //No se cumplen los criterios para la nueva contraseña, la contraseña de confirmación no coincide o la nueva contraseña es igual a la actual. Por favor, revise.";
                    break;
                  case 409:
                    this.translateService.stream("MENSAJES.ACCIONNOPERMITIDA").subscribe(resul => {
                      this.textoError = resul
                      // mensaje = resul
                    }); //token incorrecto
                    break;
                  case 500:
                    this.translateService.stream("MENSAJES.ERRORSERVER").subscribe(resul => {
                      this.textoError = resul
                      // mensaje = resul
                    });
                    break;
                  case 0: //problema de conexion con el backEnd
                    this.translateService.stream("MENSAJES.ERRORINTENTARDENUEVO").subscribe(resul => {
                      this.textoError = resul
                      // mensaje = resul
                    });
                    break;
                }
                setTimeout(() => {
                  this.spinner.hide();
                  this.error = true;
                  // this.textoError = mensaje;
                  // this.toastr.error(mensaje);
                }, 500);
              });
          } else {
            this.error = true;
            this.password = '';
            this.repeticionPassword = '';
            this.translateService.stream("MENSAJES.ERRORMODIFICARPASSWORD").subscribe(resul => {
              this.textoError = resul
            });
          }
        } else {
          // setTimeout(() => {
          // this.spinner.hide();
          this.error = true;
          this.password = '';
          this.repeticionPassword = '';
          // this.toastr.error('Por favor, revise', 'Existen campos sin completar');
          this.translateService.stream("MENSAJES.DATOSINCORRECTOS").subscribe(resul => {
            this.textoError = resul
          });
          // }, 1500);
        }
      }

    } else {
      // setTimeout(() => {
      // this.spinner.hide();
      this.error = true;
      // this.toastr.error('Por favor, revise', 'Existen campos sin completar');
      this.translateService.stream("MENSAJES.FALTANCAMPOS").subscribe(resul => {
        this.textoError = resul
      });
      // }, 1500);
    }
  }

  abrirModal() {
    const dialogRefInformativo = this.dialog.open(dialogModalOlvidePassword, {
      data: {}
    });
    dialogRefInformativo.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

}



/*SELECTOR PARA LA CLASE MODAL*/

@Component({
  selector: 'modal',
  templateUrl: './modal.html',
  styleUrls: ['./modal.scss']
})
export class dialogModalOlvidePassword {

  nodoVentanaModal: string;

  tokenExpirado: boolean = false;

  simbolosEspeciales: string = '¡!$?&#@/\()=¿?*[];,:._<>+-';

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }


  ngOnInit(): void {
    if (this.data && this.data.itemEditado) {
      this.nodoVentanaModal = this.data.itemEditado;
      console.log(this.nodoVentanaModal)
    }
  }
}