<button mat-icon-button class="close-button" [mat-dialog-close]="true">
  <mat-icon class="close-icon" >close</mat-icon>
</button>

<h2 style="color:black;" mat-dialog-title >{{"HEADER.CONFIRMARCIERRE" | translate}}</h2>
<div mat-dialog-content>
  <p class="parrafoModal" style="text-align: center;">
    {{"HEADER.PREGUNTARCIERRESESION" | translate}}
</p>
</div>

<div mat-dialog-actions>
    <button type="button" class="btn btn-warning btn-lg posicionamientoBtn" style="margin-top:.75em;margin-bottom:.5em;width:100%;font-weight: bold;"  [mat-dialog-close] = "true" (click)="confirmarLogOut()" >{{"HEADER.CERRARSESION" | translate}}</button> 
    <button type="button" class="btn btn-secondary btn-lg posicionamientoBtn" style="margin-top:.75em;margin-bottom:.5em;width:100%;font-weight: bold;"  [mat-dialog-close] = "true">{{"GLOBAL.CANCELAR" | translate}}</button> 
  </div>
