import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Usuario } from '../Models/usuario.model';
import {environment} from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class UsuarioService {

  urlApi:any =  environment.url + "/users";


  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(private http: HttpClient) { }

  // getUsuarios(): Observable<Usuario[]>{
  //   return this.http.get<Usuario[]>(this.urlApi);
  // }

  createUsuario(usuario: Usuario):Observable<Usuario>{
    return this.http.post<Usuario>(this.urlApi, usuario, this.httpOptions);
  }

  modifyUserPassword(usuario: Usuario):Observable<Usuario>{ 
    return this.http.post<Usuario>(this.urlApi +'/change-password', usuario, this.httpOptions);
  }

  getUsuarioDetails(): Observable<Usuario>{
    return this.http.get<Usuario>(this.urlApi + '/getUserDetails'); 
  }

  validateNewUserData(usuario:Usuario): Observable<Usuario>{
    // let params = new HttpParams().append('id',id).append('contractNumber',contractNumber);
    return this.http.post<Usuario>(this.urlApi + '/validateNewUserData', usuario, this.httpOptions); 
  }

  forgotPassword(usuario:string, language:string): Observable<Usuario>{
    let send = { email: usuario, language: language }
    return this.http.post<Usuario>(this.urlApi + '/forgotPassword', send, this.httpOptions); 
  }

  resetPassword(usuario: Usuario): Observable<any>{
    return this.http.post<Usuario>(this.urlApi + '/resetPassword', usuario, this.httpOptions); 
  }


}


