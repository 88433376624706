import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { ContratoService } from 'src/app/Services/contrato.service';
import { FacturaService } from 'src/app/Services/factura.service';
import { HeaderNavService } from 'src/app/Services/headerNavService';
import { UtilsService } from 'src/app/utils/utilsService';

@Component({
  selector: 'crosscheck',
  templateUrl: './crosscheck.component.html',
  styleUrls: ['./crosscheck.component.scss']
})
export class CrosscheckComponent implements OnInit {

  nif: string;

  errorNif: boolean = false;

  textoErrorNif: string;

  nombreFichero: string;

  tipoDocumento: string;

  constructor(private router: Router, public utilsService: UtilsService, private route: ActivatedRoute,
    private facturasService: FacturaService, private contratoService: ContratoService, private spinner: NgxSpinnerService, private headerNavService: HeaderNavService, public translateService: TranslateService) { }

  ngOnInit(): void {

    this.headerNavService.visibilidadHeader(false);

    this.route.queryParams.subscribe(params => {
      this.utilsService.cargarIdiomaNoLogueado(params.language)

      if (params && params.invoiceName && params.invoiceName != "") {
        this.nombreFichero = params.invoiceName;
        this.tipoDocumento = "facturas";
      } else if (params && params.contractName && params.contractName != "") {
        this.nombreFichero = params.contractName;
        this.tipoDocumento = "contratos";
      }
      if (!this.nombreFichero) { //si has accedido mediante la url y no desde el mail, redirección a login
        this.redirigirHome();
      }
    });
  }

  // https://ekiluz-gateway.azurewebsites.net/back/contracts/downloadMail?contractName=SFfacturapre.pdf&userId=12312312A


  redirigirHome() {
    this.router.navigate(['login']);
    // window.location.reload();
  }

  enviarNif() {
    this.errorNif = false;
    if (this.utilsService.isNotNullOrNotEmptyOrNotUndefined(this.nif)) {
      // if (this.utilsService.esDniValido(this.nif)) {
      if (this.nombreFichero != '' && this.nombreFichero != undefined) {
        if (this.tipoDocumento == "facturas") {
          this.descargaFactura(this.nombreFichero, this.nif);
        } else {
          this.descargarContrato(this.nombreFichero, this.nif);
        }
      } else {
        this.translateService.stream("MENSAJES.ERRORDESCARGAFICHERO").subscribe(resul => {
          this.textoErrorNif = resul
        });
        this.errorNif = true;
        this.nif = '';
      }
      // } else {
      //   this.textoErrorNif = "El DNI / NIF introducido no tiene el formato esperado. Por favor, inténtelo de nuevo o llámenos.";
      //   this.errorNif = true;
      //   this.nif = '';
      // }
    } else {
      this.translateService.stream("MENSAJES.FALTANCAMPOS").subscribe(resul => {
        this.textoErrorNif = resul
      });
      this.errorNif = true;
    }
  }

  descargaFactura(nombreFichero: string, nif: string) {
    this.spinner.show();
    this.facturasService.descargarFacturaDesdeMail(nombreFichero, nif).subscribe(
      (response: any) => {
        console.log(response);
        this.utilsService.generarPDFCrosscheck(response, nombreFichero);
        setTimeout(() => {
          this.spinner.hide();
          this.redirigirHome();
        }, 500);
      },
      err => {
        console.error("Ocurrió un error: " + err.message);
        let errorCode = err.status;
        // let mensaje;
        switch (errorCode) {
          case 404:
            this.translateService.stream("MENSAJES.NOASOCIADO").subscribe(resul => {
              this.textoErrorNif = resul
              // mensaje = resul
            });
            break;
          case 500:
            this.translateService.stream("MENSAJES.ERRORSERVER").subscribe(resul => {
              this.textoErrorNif = resul
              // mensaje = resul
            });
            break;
          case 0: //problema de conexion con el backEnd
            this.translateService.stream("MENSAJES.ERRORINTENTARDENUEVO").subscribe(resul => {
              this.textoErrorNif = resul
              // mensaje = resul
            });
            break;
        }
        setTimeout(() => {
          this.spinner.hide();
          // this.textoErrorNif = mensaje;
          this.errorNif = true;
          this.nif = '';
        }, 500);
      });
  }

  descargarContrato(nombreFichero: string, nif: string) {
    this.spinner.show();
    this.contratoService.descargarContratoDesdeMail(nombreFichero, nif).subscribe(
      (response: any) => {
        console.log(response);
        this.utilsService.generarPDFCrosscheck(response, nombreFichero);
        setTimeout(() => {
          this.spinner.hide();
          this.redirigirHome();
        }, 500);
      },
      err => {
        console.error("Ocurrió un error: " + err.message);
        let errorCode = err.status;
        // let mensaje;
        switch (errorCode) {
          case 404:
            this.translateService.stream("MENSAJES.ERRORDESCARGACONTRATODNI").subscribe(resul => {
              this.textoErrorNif = resul
              // mensaje = resul
            });
            break;
          case 500:
            this.translateService.stream("MENSAJES.ERRORSERVER").subscribe(resul => {
              this.textoErrorNif = resul
              // mensaje = resul
            });
            break;
          case 0: //problema de conexion con el backEnd
            this.translateService.stream("MENSAJES.ERRORINTENTARDENUEVO").subscribe(resul => {
              this.textoErrorNif = resul
              // mensaje = resul
            });
            break;
        }
        setTimeout(() => {
          this.spinner.hide();
          // this.textoErrorNif = mensaje;
          this.errorNif = true;
          this.nif = '';
        }, 500);
      });
  }

}
