<!--HEADER-->
<div *ngIf="mostrarHeader">
<div  class="row white navBarHeight col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 noPaddingMarginRow" style="margin-right: 0px; ">
    <div class="row col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 noPaddingMarginRow">
        <div class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 alignHeader" id="containerLogo">
             <!-- <img src="assets/img/logo.png" class="separationTop" (click)="rutaVariableLogo()">   -->
            <img src="assets/img/logo-ekiluz-energia.svg" id="logoEkiluzEnergia" class="separationTop" (click)="rutaVariableLogo()"> 
        </div>
    </div>
     <!-- <div class=" row  col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 noPaddingMarginRow" >  -->
       <!--*ngIf="mostrarDireccionUsuario; else perfil"-->
        <!-- <div ngbDropdown class="d-inline-block infoDireccion offset-md-3" id="borderRight">
            <button class="btn col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 userDirection" id="dropdownBasic1" ngbDropdownToggle>
            <img src="assets/icon/electricity.png" style="height:20px; width:20px;margin-bottom:5px;" > {{direccionUsuario}}</button>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                <button ngbDropdownItem  routerLink="/contacto">ej1</button>
                <button ngbDropdownItem  routerLink="/login">ej2</button>
            </div>
        </div> -->
     <!-- </div> -->
    <!-- <ng-template #perfil>
      <div class=" row  col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 noPaddingMarginRow">
        <div>
          &nbsp;
        </div>
      </div>
    </ng-template> -->
    <div class=" row  col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 noPaddingMarginRow" *ngIf="mostrarUsuarioLogueado; else ayuda"> 

      <!-- <select class="form-select sinBorde" aria-label="Default select example">
        <option selected disabled>Selector de idioma</option>
        <option value="es">Español</option>
        <option value="eu">Euskera</option>
      </select> -->



      <div ngbDropdown class="d-inline-block offset-md-6">
        <button class="btn col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 selectorIdioma" id="dropdownBasicIdioma" ngbDropdownToggle>
          <div id="contenedorImgTexto">
            <span style="font-size:.9rem;" id="nombreUsuario" >{{'SELECTOR.TITLE' | translate }}</span>
          </div>
        </button> 
        <div ngbDropdownMenu aria-labelledby="dropdownBasicIdioma">
            <button ngbDropdownItem (click)="utilsService.actualizarIdioma('es', false)" [ngClass]="translateService.currentLang == 'es' ? 'idiomaSeleccionado' : ''">{{'SELECTOR.SPANISH' | translate }} <i class="fa fa-check" *ngIf="translateService.currentLang === 'es'"></i></button>
            <button ngbDropdownItem (click)="utilsService.actualizarIdioma('eu', false)" [ngClass]="translateService.currentLang == 'eu' ? 'idiomaSeleccionado' : ''">{{'SELECTOR.EUSKERA' | translate }} <i class="fa fa-check" *ngIf="translateService.currentLang === 'eu'"></i></button> 
        </div>
    </div>




    <div ngbDropdown class="d-inline-block">
        <button class="btn col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 userName" id="dropdownBasicPerfil" ngbDropdownToggle *ngIf="nombreUsuario != undefined">
            <!-- <img class="rounded-circle imgUser" alt="100x100" src="assets/img/user.png"
            data-holder-rendered="true"> -->
          <div id="contenedorImgTexto">
            <span style="font-size:.9rem;" id="nombreUsuario">{{nombreUsuario}} {{apellidosUsuario}}</span>
          </div>
        </button> 
        <div ngbDropdownMenu aria-labelledby="dropdownBasicPerfil">
            <button ngbDropdownItem  routerLink="/contacto">{{'HEADER.PERFIL' | translate}}</button>
            <!-- <button ngbDropdownItem>Ayuda</button>  -->
             <button ngbDropdownItem class="logout" (click)="logOut()">{{'HEADER.CERRARSESION' | translate}}</button> 

            <!-- <button ngbDropdownItem  routerLink="/login">Salir</button> -->
        </div>
    </div>
    </div>
     <ng-template #ayuda>
      <div class=" row  col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 noPaddingMarginRow">
      <div class="col-12 col-sm-12 col-lg-12 col-xl-12 col-md-12 offset-md-1">
        <span>{{"LOGIN.PROBLEMASACCESO" | translate}}</span>
        <br>
        <span style="color:#1f00f7;padding-left: 2em;font-weight: bold;">{{"LOGIN.CONTACTANOS" | translate}}</span>
      </div>
      </div>
    </ng-template> 
</div>
<!--FIN HEADER-->
<!--NAVBAR-->
<div  class="row white col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 noPaddingMarginRow" id="barraNav" *ngIf="mostrarNavegacion">
<nav class="navbar navbar-expand-lg navbar-light alignHeader noPaddingMarginRow " style="padding:0px !important;">
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNavDropdown">
      <ul class="navbar-nav">
        <!-- <li class="nav-item active" id="consumo" > -->
          <!--<span class="sr-only">(current)</span>-->
          <!-- <a class="nav-link" href="" routerLink="/principal" style="line-height: 35px !important;">Mi Consumo </a> -->
           <!-- <a class="nav-link" href="" routerLink="block" style="line-height: 35px !important;">Mi Consumo </a> -->

          <!-- <button class="nav-link" routerLink="/principal" style="line-height: 35px !important" disabled> Mi Consumo </button> -->
        <!-- </li> -->
        <li class="nav-item active" id="facturas">
          <a class="nav-link"  href="" routerLink="/facturas" style="line-height: 35px !important;" >{{'HEADER.FACTURAS' | translate}}</a>
        </li>
        <li class="nav-item"  id="contrato">
          <a class="nav-link" href=""  routerLink="/contratos">{{'HEADER.CONTRATOS' | translate}}</a>
        </li>
      </ul>
    </div>
  </nav>
  </div>
</div>
<!--FIN NAVBAR-->

