import { Component, Inject, OnInit } from '@angular/core';
import { HeaderNavService } from '../../Services/headerNavService';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { UtilsService } from '../../utils/utilsService';
import { OAuthService } from 'angular-oauth2-oidc';
import { UsuarioService } from 'src/app/Services/usuario.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Usuario } from 'src/app/Models/usuario.model';
import { LoginService } from 'src/app/Services/login.service';
import { Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

declare var $;
@Component({
  selector: 'contacto',
  templateUrl: './contacto.component.html',
  styleUrls: ['./contacto.component.scss']
})
export class ContactoComponent implements OnInit {

  datosPersonales: boolean = true;
  datosAcceso: boolean = false;
  datosDocumentos: boolean = false;

  modoEditarNoActivoTelefono: boolean = true;
  modoEditarNoActivoEmail: boolean = true;
  modoEditarNoActivoPassword: boolean = true;

  telefonoOriginal: any;
  emailOriginal: any;
  passOriginal: any;


  nombreUsuario: string;
  apellidosUsuario: string;
  dniUsuario: string;
  tlfUsuario: string;
  mailUsuario: string;
  language: string;


  passUsuarioDummy: string = "Passdummy_ekiluz" //password para mostrar en datos de acceso previo a editar
  oldPassword: string;
  newPassword: string;
  repPassword: string;

  tokenExpirado: boolean = false;

  errorPerfil: boolean = false;
  textoErrorPerfil: string;

  mensajeToast: string
  mensajeToastArray: string[] = new Array<string>(12)

  constructor(private headerNavService: HeaderNavService, public dialog: MatDialog,
    private toastr: ToastrService, private utilsService: UtilsService,
    private oAuthService: OAuthService, private userService: UsuarioService, private spinner: NgxSpinnerService,
    private loginService: LoginService, private router: Router, public translateService: TranslateService) { }

  ngOnInit(): void {
    this.utilsService.existeUsuarioLogueado();
    if (this.utilsService.existeToken()) {
      this.obtenerDetallesUsuario();
      this.headerNavService.visibilidadMenu(false);
    } else {
      this.headerNavService.visibilidadHeader(false);
    }
    this.passOriginal = this.passUsuarioDummy; //la llamo dummy porque no se va a ver por el usuario nunca, siempre la va a modificar

  }



  ngAfterViewInit(): void {

    // setTimeout(() => {
    //   $("#containerFooter").show();  
    //   }, 600);
    // $("#containerFooter").css({"position":"fixed","bottom":"0"});
    // $("#containerFooter").show();  

    let componentScope = this; //guardamos el alcance del componente para evitar errores entre jQuery en la asignación del menu y el muestreo de las templates
    $(".esBoton").on("click", function () {
      // componentScope.spinner.show();
      $(".list-group").find(".active").removeClass("active"); //eliminar clase activa por defecto
      $(this).addClass("active"); //añadir clase activa al elemento seleccionado
      if (this.textContent.includes("acceso") || this.textContent.includes("Sarbide datuak")) {
        // setTimeout(() => {
        componentScope.spinner.hide();
        componentScope.datosPersonales = false;
        componentScope.datosDocumentos = false;
        componentScope.datosAcceso = true;
        // }, 250);
      } else if (this.textContent.includes("documentos") || this.textContent.includes("Nire dokumentuak")) {
        // setTimeout(() => {
        componentScope.spinner.hide();
        componentScope.datosPersonales = false;
        componentScope.datosAcceso = false;
        componentScope.datosDocumentos = true;
        // }, 250);
      } else {
        // setTimeout(() => {
        // componentScope.spinner.hide();
        componentScope.datosAcceso = false;
        componentScope.datosDocumentos = false;
        componentScope.datosPersonales = true;
        // }, 250);
      }
    });
  }

  cambiarIcono(evento, inputId, iconId) {
    if (evento) {
      let tipoInput = $("#" + inputId).attr("type");
      if (tipoInput == "password") {
        $("#" + inputId).prop("type", "text");
        $("#" + iconId).attr("class", "fa fa-eye");
      } else {
        $("#" + inputId).prop("type", "password");
        $("#" + iconId).attr("class", "fa fa-eye-slash");
      }
    }
  }

  obtenerDetallesUsuario() {
    this.spinner.show();
    this.userService.getUsuarioDetails().subscribe(
      (response: any) => {
        if (response && response.records && response.records[0]) {
          console.log(response)
          this.nombreUsuario = response.records[0].name;
          this.apellidosUsuario = response.records[0].surname;
          this.dniUsuario = response.records[0].id;
          this.tlfUsuario = response.records[0].phoneNumber;
          this.mailUsuario = response.records[0].email;
          this.language = response.records[0].language;

          if (this.utilsService.isNullOrEmptyOrUndefined(this.nombreUsuario)) {
            this.translateService.stream("MENSAJES.RELLENEINFOATC").subscribe(resul => {
              this.nombreUsuario = resul
            });
          }
          if (this.utilsService.isNullOrEmptyOrUndefined(this.apellidosUsuario)) {
            this.translateService.stream("MENSAJES.RELLENEINFOATC").subscribe(resul => {
              this.apellidosUsuario = resul
            });
          }
          if (this.utilsService.isNullOrEmptyOrUndefined(this.apellidosUsuario)) {
            this.translateService.stream("MENSAJES.RELLENEINFOATC").subscribe(resul => {
              this.apellidosUsuario = resul
            });
          }
          if (this.utilsService.isNullOrEmptyOrUndefined(this.tlfUsuario)) {
            this.translateService.stream("MENSAJES.RELLENEINFOATC").subscribe(resul => {
              this.tlfUsuario = resul
            });
          }

          setTimeout(() => {
            this.spinner.hide();
          }, 500);
        }
      },
      err => {
        console.error("Ocurrió un error: " + err.message);
        let errorCode = err.status;
        // let mensaje;
        switch (errorCode) {
          case 500:
            this.translateService.stream("MENSAJES.ERRORSERVER").subscribe(resul => {
              this.mensajeToast = resul
              // mensaje = resul
            });
            break;
          case 0: //problema de conexion con el backEnd
            this.translateService.stream("MENSAJES.ERRORINTENTARDENUEVO").subscribe(resul => {
              this.mensajeToast = resul
              // mensaje = resul
            });
            break;
          case 401: //problema de token expirado
            this.translateService.stream("MENSAJES.SESIONCADUCADA").subscribe(resul => {
              this.mensajeToast = resul
              // mensaje = resul
            });
            this.tokenExpirado = true;
            this.loginService.setLogoutSession();
            this.headerNavService.visibilidadHeader(false);
            break;
        }
        setTimeout(() => {
          this.spinner.hide();
          if (errorCode == 401) {
            this.toastr.error('', this.mensajeToast, {
              disableTimeOut: true,
            });
          } else {
            this.toastr.error(this.mensajeToast);
          }
        }, 500);
        if (this.tokenExpirado) {
          this.router.navigate(['login']);
        }
      }
    );
  }



  editarTelefono(accion: string) {
    if (accion === "editar") {
      this.modoEditarNoActivoTelefono = false;
    } else {
      this.modoEditarNoActivoTelefono = true;
    }
  }

  editarEmail(accion: string, $event) {
    $event.preventDefault(); //EL MAIL NO ES EDITABLE
    // if (accion === "editar"){
    //   this.modoEditarNoActivoEmail = false;
    // } else{
    //   this.modoEditarNoActivoEmail = true;
    // }
  }

  editarPassword(accion: string) {
    if (accion === "editar") {
      this.modoEditarNoActivoPassword = false;
    } else {
      this.modoEditarNoActivoPassword = true;
    }
  }

  modificarPassword(usuario: Usuario) {
    this.spinner.show();
    this.userService.modifyUserPassword(usuario).subscribe(
      (response: any) => {
        if (response && response.records && response.records[0]) {
          console.log(response)
          setTimeout(() => {
            this.spinner.hide();
          }, 500);
        }
      },
      err => {
        console.error("Ocurrió un error: " + err.message);
      }
    );
  }

  abrirModal(itemEditado: string) {
    const dialogRef = this.dialog.open(dialogModal, {
      data: {
        itemEditado: itemEditado, objetoPassword: { repPassword: this.repPassword, newPassword: this.newPassword, oldPassword: this.oldPassword, id: this.dniUsuario },
        componentInstance: this
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      if (result || result == undefined) { //result == true click en continuar o en la x || undefined = click fuera del modal
        if (itemEditado == "telefono") {  //cerramos la edición
          this.modoEditarNoActivoTelefono = true;
        } else if (itemEditado == "email") {
          this.modoEditarNoActivoEmail = true;
        } else {
          // this.modoEditarNoActivoPassword = true;
        }
      }
    });
  }

  abrirModalInformativo(itemEditado: string) {
    const dialogRefInformativo = this.dialog.open(dialogModal, {
      data: { itemEditado: itemEditado }
    });
    dialogRefInformativo.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  guardarOperacion(itemEditado: string) {
    this.errorPerfil = false;
    // let mensaje1, mensaje2
    if (itemEditado.includes("telefono")) { //ANULADO
      if (this.telefonoOriginal != this.tlfUsuario) {
        if (this.utilsService.comprobarNumero(this.tlfUsuario) && this.utilsService.isNotNullOrNotEmptyOrNotUndefined(this.tlfUsuario)
          && this.utilsService.esNumeroDeTelefono(this.tlfUsuario)) {
          this.translateService.stream("MENSAJES.OPERACIONEXITO").subscribe(resul => {
            this.mensajeToastArray[0] = resul
          });
          this.translateService.stream("MENSAJES.TELEFONOGUARDADO").subscribe(resul => {
            this.mensajeToastArray[1] = resul
          });
          this.toastr.success(this.mensajeToastArray[0], this.mensajeToastArray[1]);
          this.abrirModal(itemEditado);
          //CALL BACKEND

        } else {
          this.translateService.stream("MENSAJES.OPERACIONERRONEA").subscribe(resul => {
            this.mensajeToastArray[2] = resul
          });
          this.translateService.stream("MENSAJES.ERRORVALIDACIONTELEFONO").subscribe(resul => {
            this.mensajeToastArray[3] = resul
          });
          this.toastr.error(this.mensajeToastArray[2], this.mensajeToastArray[3]);
          // this.data.nuevoTlfno = this.data.original;
        }
      } else {
        this.translateService.stream("MENSAJES.OPERACIONERRONEA").subscribe(resul => {
          this.mensajeToastArray[4] = resul
        });
        this.translateService.stream("MENSAJES.MISMOTELEFONO").subscribe(resul => {
          this.mensajeToastArray[5] = resul
        });
        this.toastr.error(this.mensajeToastArray[4], this.mensajeToastArray[5]);
      }
    } else if (itemEditado.includes("email")) { //ANULADO
      if (this.emailOriginal != this.mailUsuario) {
        if (this.utilsService.esCorreoElectronico(this.mailUsuario)) {
          this.translateService.stream("MENSAJES.OPERACIONEXITO").subscribe(resul => {
            this.mensajeToastArray[6] = resul
          });
          this.translateService.stream("MENSAJES.CORREOGUARDADO").subscribe(resul => {
            this.mensajeToastArray[7] = resul
          });
          this.toastr.success(this.mensajeToastArray[6], this.mensajeToastArray[7]);
          this.abrirModal(itemEditado);
          //CALL BACKEND
        } else {
          this.translateService.stream("MENSAJES.OPERACIONERRONEA").subscribe(resul => {
            this.mensajeToastArray[8] = resul
          });
          this.translateService.stream("MENSAJES.ERRORVALIDACIONCORREO").subscribe(resul => {
            this.mensajeToastArray[9] = resul
          });
          this.toastr.error(this.mensajeToastArray[8], this.mensajeToastArray[9]);

        }
      } else {
        this.translateService.stream("MENSAJES.OPERACIONERRONEA").subscribe(resul => {
          this.mensajeToastArray[10] = resul
        });
        this.translateService.stream("MENSAJES.MISMOCORREO").subscribe(resul => {
          this.mensajeToastArray[11] = resul
        });
        this.toastr.error(this.mensajeToastArray[10], this.mensajeToastArray[11]);
      }

    } else if (itemEditado.includes("password")) {
      if (this.utilsService.isNotNullOrNotEmptyOrNotUndefined(this.oldPassword) && this.utilsService.isNotNullOrNotEmptyOrNotUndefined(this.newPassword) &&
        this.utilsService.isNotNullOrNotEmptyOrNotUndefined(this.repPassword)) {
        if (this.utilsService.requisitosSeguridadPassword(this.newPassword) && this.utilsService.requisitosSeguridadPassword(this.repPassword)) {
          if (this.newPassword !== this.repPassword) {
            // this.toastr.error('Por favor, revise', 'Las contraseñas introducidas no coinciden');
            this.errorPerfil = true;
            this.translateService.stream("MENSAJES.DATOSINCORRECTOS").subscribe(resul => {
              this.textoErrorPerfil = resul
            })
            this.newPassword = '';
            this.repPassword = '';
          } else {
            //si el usuario quiere modificar la contraseña por la misma que ya tenía:
            if ((this.oldPassword === this.newPassword) && (this.newPassword === this.repPassword)) {
              this.errorPerfil = true;
              this.translateService.stream("MENSAJES.DATOSINCORRECTOS").subscribe(resul => {
                this.textoErrorPerfil = resul
              }); //La contraseña actual no es correcta, no se cumplen los criterios para la nueva contraseña, la contraseña de confirmación no coincide o las nueva contraseña es igual a la actual. Por favor, revise.';
              this.newPassword = '';
              this.repPassword = '';
              this.oldPassword = '';

            } else {
              this.abrirModal(itemEditado);
            }
          }
        } else {
          // this.toastr.error('Por favor, revise', 'La nueva contraseña no cumple los criterios de seguridad');
          this.errorPerfil = true;
          this.translateService.stream("MENSAJES.DATOSINCORRECTOS").subscribe(resul => {
            this.textoErrorPerfil = resul
          }); //La contraseña actual no es correcta, no se cumplen los criterios para la nueva contraseña, la contraseña de confirmación no coincide o las nueva contraseña es igual a la actual. Por favor, revise.';
          this.newPassword = '';
          this.repPassword = '';
          this.oldPassword = '';
        }
      } else {
        this.errorPerfil = true;
        this.translateService.stream("MENSAJES.FALTANCAMPOS").subscribe(resul => {
          this.textoErrorPerfil = resul
        });
      }

    }

  }

  ngOnDestroy(): void {
    // $("#containerFooter").hide();  
    // $("#containerFooter").css({"position":"","bottom":""});
    this.headerNavService.visibilidadMenu(true);
    // this.headerNavService.visibilidadDirUsuario(true);
  }

}

/*SELECTOR PARA LA CLASE MODAL*/

@Component({
  selector: 'modal',
  templateUrl: './modal.html',
  styleUrls: ['./modal.scss']
})
export class dialogModal {

  nodoVentanaModal: string;

  tokenExpirado: boolean = false;

  simbolosEspeciales: string = '¡!$?&#@/\()=¿?*[];,:._<>+-';

  mensajeToast: string
  mensajeToast1: string
  mensajeToast2: string

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private toastr: ToastrService, private utilsService: UtilsService,
    private spinner: NgxSpinnerService, private usuarioService: UsuarioService, private loginService: LoginService,
    private headerNavService: HeaderNavService, private router: Router, private translateService: TranslateService) { }


  ngOnInit(): void {
    if (this.data && this.data.itemEditado) {
      this.nodoVentanaModal = this.data.itemEditado;
      console.log(this.nodoVentanaModal)
    }
  }

  confirmarCambioPassword() {
    //recibo los datos para confirmación en el modal
    if (this.data && this.data.itemEditado && this.data.objetoPassword && this.data.objetoPassword.id && this.data.componentInstance) {
      let userPassModify: Usuario = {
        id: this.data.objetoPassword.id, password: this.data.objetoPassword.newPassword, role: null, name: null, surname: null, email: null, contractNumber: null,
        phoneNumber: null, totalSize: null, oldPassword: this.data.objetoPassword.oldPassword, language: this.translateService.currentLang
      };
      this.cambiarPassword(userPassModify, this.data.componentInstance);
    }
  }

  cambiarPassword(usuario: Usuario, componentInstance: ContactoComponent) {
    componentInstance.errorPerfil = false;
    this.spinner.show();
    this.usuarioService.modifyUserPassword(usuario).subscribe(
      (response: any) => {
        setTimeout(() => {
          this.spinner.hide();
          componentInstance.oldPassword = '';
          componentInstance.newPassword = '';
          componentInstance.repPassword = '';
          componentInstance.modoEditarNoActivoPassword = true; //cerramos la edición cuando se ha hecho correctamente
          // let mensaje1, mensaje2
          this.translateService.stream("MENSAJES.PASSWORDACTUALIZADA").subscribe(resul => {
            this.mensajeToast1 = resul
          });
          this.toastr.success('', this.mensajeToast1, {
            disableTimeOut: true
          });
          this.translateService.stream("MENSAJES.DESDENUEVAPASSWORD").subscribe(resul => {
            this.mensajeToast2 = resul
          });
          this.toastr.success('', this.mensajeToast2, {
            disableTimeOut: true
            // positionClass: 'toast-top-center',
          });
        }, 500);

      },
      err => {
        console.error("Ocurrió un error: " + err.message);
        let errorCode = err.status;
        // let mensaje;
        switch (errorCode) {
          case 409:
            this.translateService.stream("MENSAJES.DATOSINCORRECTOS").subscribe(resul => {
              this.mensajeToast = resul
              // mensaje = resul
            });;//"La contraseña actual no es correcta, no se cumplen los criterios para la nueva contraseña, la contraseña de confirmación no coincide o las nueva contraseña es igual a la actual. Por favor, revise.";
            break;
          case 404:
            this.translateService.stream("MENSAJES.PASSWORDUSUARIONOEXISTE").subscribe(resul => {
              this.mensajeToast = resul
              // mensaje = resul
            });;
            break;
          case 500:
            this.translateService.stream("MENSAJES.ERRORSERVER").subscribe(resul => {
              this.mensajeToast = resul
              // mensaje = resul
            });;
            break;
          case 0: //problema de conexion con el backEnd
            this.translateService.stream("MENSAJES.ERRORINTENTARDENUEVO").subscribe(resul => {
              this.mensajeToast = resul
              // mensaje = resul
            });;
            break;
          case 401: //problema de token expirado
            this.translateService.stream("MENSAJES.SESIONCADUCADA").subscribe(resul => {
              this.mensajeToast = resul
              // mensaje = resul
            });;
            this.tokenExpirado = true;
            this.loginService.setLogoutSession();
            this.headerNavService.visibilidadHeader(false);
            break;
        }
        setTimeout(() => {
          this.spinner.hide();
          if (errorCode == 409 || errorCode == 404) {
            // this.toastr.error('Por favor, revise', mensaje);
            componentInstance.errorPerfil = true;
            componentInstance.textoErrorPerfil = this.mensajeToast;
            componentInstance.oldPassword = '';
            componentInstance.newPassword = '';
            componentInstance.repPassword = '';
          } else {
            if (errorCode == 401) {
              this.toastr.error('', this.mensajeToast, {
                disableTimeOut: true,
              });
            } else {
              componentInstance.textoErrorPerfil = this.mensajeToast;
            }
          }
        }, 500);

        if (this.tokenExpirado) {
          this.router.navigate(['login']);
        }
      }
    );
  }
}




