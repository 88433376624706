import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Factura } from '../Models/factura.model';
import {environment} from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class FacturaService {

  urlApi:any =  environment.url + "/invoices";

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(private http: HttpClient) { }

  getFacturas(): Observable<Factura[]>{
    return this.http.get<Factura[]>(this.urlApi);
  }

  descargarFactura(urlFactura:string): Observable<any>{
    return this.http.get<Blob>(this.urlApi + "/download/" + urlFactura ,{responseType:'blob' as 'json'});
  }

  descargarFacturaDesdeMail(invoiceName, userId): Observable<any>{
    let parameters = new HttpParams().append('invoiceName',invoiceName).append('userId',userId);
    return this.http.get<Blob>(this.urlApi + "/downloadMail?", {responseType:'blob' as 'json', params:parameters});
  }
}
