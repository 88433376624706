<div class="row  col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 noPaddingMarginRow" id="divPaddingPerfil">


    <div class="col-4 col-md-4 col-sm-4 col-lg-4 col-xl-4" style="margin-top:1.5em;" id="principalSecciones">
        <div class="  col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10 " id="paddingMovilBloque1">
            <div class="list-group">
                <div class="list-group-item list-group-item-action flex-column align-items-start">
                    <div class=" d-flex w-100 justify-content-initial">
                        <!--d-flex-->
                        <!-- <img class="rounded-circle imgUser" alt="100x100" src="assets/img/user.png"
                            data-holder-rendered="true"> -->
                        <h5 class="mb-1" style="color:black;margin-top:0.6em;font-weight: 400;">{{nombreUsuario}}
                            {{apellidosUsuario}}</h5> <!-- *ngIf="token" {{claims.name}}-->
                        <!-- <h5 class="volverCliente mb-1"> Volver al Área de cliente</h5> -->
                    </div>
                    <div class="volverCliente"> <!--ml-5-->
                        <a href="" routerLink="/facturas">{{'CONTACTO.VOLVERATRAS' | translate }}</a>
                    </div>
                </div>
                <button type="button" class="list-group-item list-group-item-action active esBoton">{{'CONTACTO.MISDATOS' | translate }} <i
                        class="fa fa-chevron-right rightIcon"></i></button>
                <button type="button" class="list-group-item list-group-item-action esBoton">{{'CONTACTO.DATOSACCESO' | translate }} <i
                        class="fa fa-chevron-right rightIcon"></i></button>
                <button type="button" class="list-group-item list-group-item-action esBoton">{{'CONTACTO.MISDOCUMENTOS' | translate }} <i
                        class="fa fa-chevron-right rightIcon"></i></button>

            </div>
        </div>
        <div class="  col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10 " style="margin-top: 2.5em;margin-bottom: 1em;" id="paddingMovilBloque2">
            <div class="list-group">
                <div class="list-group-item list-group-item-action flex-column align-items-start">
                    <div>
                        <p style="font-size: 1.25rem;font-weight: 400;color:black;">{{'CONTACTO.SOPORTE' | translate }}</p>
                    </div>

                    <div>
                         <button type="button" class="btn btn-warning bigBtn"><a [href]="translateService.currentLang == 'eu' ? 'https://ekiluzenergia.com/eu/faqs/' : 'https://ekiluzenergia.com/faqs/'"
                                target="_blank"
                                style="text-decoration: none;color: black;font-size: 0.8rem;display: block;">{{'CONTACTO.FAQ' | translate }}</a></button> <!-- TODO poner url Euskera -->
                            </div>
                            <div>
                                <button type="button" class="btn colorBtn" style="margin-top:1.5em;" id="btnTelefono"><i class="fa fa-phone"
                                    style="font-size:1.15rem;color:black;vertical-align: middle;" aria-hidden="true"></i><a
                                    id="telefono" href="tel:+34900293741">&nbsp;&nbsp;900293741</a></button>
                            <button type="button" class="btn colorBtn" id="writeUs" style="margin-top:1.5em;"><i
                                    class="fa fa-file" style="font-size:1rem;color:black;vertical-align: middle;"
                                    aria-hidden="true"></i>
                                <a id="escribenos"
                                    href="mailto:clientes@ekiluzenergia.com?subject=Dudas y consultas área de clientes">&nbsp;&nbsp;{{'CONTACTO.ESCRIBENOS' | translate }}</a>
                            </button>
                            </div>
                   

                    
                </div>

            </div>
        </div>
    </div>
    <div class="col-8 col-md-8 col-sm-8 col-lg-8 col-xl-8 "
        style="margin-top:1.5em;margin-bottom: 1.5em;" *ngIf="datosPersonales"> <!--offset-1 offset-md-1 offset-sm-1 offset-lg-1 offset-xl-1-->
        <div class="list-group reducirAncho">
            <div class="list-group-item list-group-item-action flex-column align-items-start">
                <div class="d-flex w-100 justify-content-between">
                    <h5 class="mb-1 cabecera" style="color:black;">{{'DATOSPERSONALES.DATOSPERSONALES' | translate }}</h5>
                </div>
            </div>
            <div class="list-group">
                <div class="list-group-item list-group-item-action flex-column align-items-start reducirAlturaListado">
                    <p class="list-group-item-heading cabecera">{{'DATOSPERSONALES.NOMBRE' | translate }}</p>
                    <p class="list-group-item-text reducirAlturaLinea" style="color:black;">{{nombreUsuario}}</p>
                </div>
            </div>
            <div class="list-group">
                <div class="list-group-item list-group-item-action flex-column align-items-start reducirAlturaListado">
                    <p class="list-group-item-heading cabecera">{{'DATOSPERSONALES.APELLIDOS' | translate }}</p>
                    <p class="list-group-item-text reducirAlturaLinea" style="color:black;">{{apellidosUsuario}}</p>
                </div>
            </div>
            <div class="list-group">
                <div class="list-group-item list-group-item-action flex-column align-items-start reducirAlturaListado">
                    <p class="list-group-item-heading cabecera">{{'DATOSPERSONALES.DNI' | translate }}</p>
                    <p class="list-group-item-text reducirAlturaLinea" style="color:black;">{{dniUsuario}}</p>
                </div>
            </div>
            <div class="list-group" *ngIf="modoEditarNoActivoTelefono; else modoEditarActivoTelefono">
                <div class="list-group-item list-group-item-action flex-column align-items-start reducirAlturaListado">
                    <p class="list-group-item-heading cabecera">{{'DATOSPERSONALES.TELEFONO' | translate }}</p>
                    <p class="list-group-item-text reducirAlturaLinea" style="color:black;">{{tlfUsuario}} </p>
                    <!-- OLD VERSION <span style="float:right;color:#2e1cf7;font-weight: bold;" id="editarTelefono" (click)="editarTelefono('editar')">Editar</span>-->
                </div>
            </div>
            <ng-template #modoEditarActivoTelefono>
                <!--OLD VERSION, aqui nunca entra ya que esta desactivada la función-->
                <div class="list-group">
                    <div class="list-group-item list-group-item-action flex-column align-items-start">
                        <input type="text" [(ngModel)]="tlfUsuario"> <span
                            style="float:right;color:#2e1cf7;font-weight: bold;" id="editarTelefono"
                            (click)="editarTelefono('cancelar')">{{'GLOBAL.CANCELAR' | translate }}</span>
                        <button type="button" class="btn btn-warning btn-lg posicionamientoBtn"
                            style="margin-top:.75em;" (click)="guardarOperacion('telefono')">{{'GLOBAL.GUARDAR' | translate }}</button>
                    </div>
                </div>
            </ng-template>
        </div>
    </div>
    <div class="col-8 col-md-8 col-sm-8 col-lg-8 col-xl-8"
        style="margin-top:1.5em;margin-bottom: 1.5em;" *ngIf="datosAcceso"> <!--offset-1 offset-md-1 offset-sm-1 offset-lg-1 offset-xl-1-->
        <div class="list-group reducirAncho">
            <div class="list-group-item list-group-item-action flex-column align-items-start">
                <div class="d-flex w-100 justify-content-between">
                    <h5 class="mb-1 cabecera" style="color:black;">{{'DATOSACCESO.DATOSACCESO' | translate }}</h5>
                </div>
            </div>
            <div class="list-group" *ngIf="modoEditarNoActivoEmail; else modoEditarActivoEmail">
                <div class="list-group-item list-group-item-action flex-column align-items-start reducirAlturaListado">
                    <p class="list-group-item-heading cabecera">{{'DATOSACCESO.EMAIL' | translate }}</p>
                    <p class="list-group-item-text reducirAlturaLinea" style="color:black;">{{mailUsuario}}</p>
                    <!--<span style="float:right;color:#2e1cf7;font-weight: bold;" id="editarMail" (click)="editarEmail('editar',$event)">Editar</span>-->
                </div>
            </div>
            <ng-template #modoEditarActivoEmail>
                <!--OLD VERSION, aqui nunca entra ya que esta desactivada la función-->
                <div class="list-group">
                    <div class="list-group-item list-group-item-action flex-column align-items-start">
                        <input type="text" [(ngModel)]="mailUsuario"> <span
                            style="float:right;color:#2e1cf7;font-weight: bold;" id="editarMail"
                            (click)="editarEmail('cancelar',$event)">{{'GLOBAL.CANCELAR' | translate }}</span>
                        <button type="button" class="btn btn-warning btn-lg posicionamientoBtn"
                            style="margin-top:.75em;" (click)="guardarOperacion('email')">{{'GLOBAL.GUARDAR' | translate }}</button>
                    </div>
                </div>
            </ng-template>
            <div class="list-group" *ngIf="modoEditarNoActivoPassword; else modoEditarActivoPassword">
                <div class="list-group-item list-group-item-action flex-column align-items-start reducirAlturaListado">
                    <p class="list-group-item-heading cabecera">{{'DATOSACCESO.PASS' | translate }}</p>
                    <!-- <p class="list-group-item-text" style="color:black;font-weight: bold;">{{dummyObject.PASSWORD}} <span style="float:right;color:#2e1cf7;font-weight: bold;" id="editarTelefono" (click)="editarPassword('editar')">Editar</span></p> -->
                    <input type="password" class="list-group-item-text" class="inputSinBorde" value="passUsuarioDummy"
                        style="line-height: 30px;" disabled><span style="float:right;color:#2e1cf7;font-weight: bold;"
                        id="editarPassword" (click)="editarPassword('editar')">{{'DATOSACCESO.MODIFICARPASS' | translate }}</span>
                </div>
            </div>
            <ng-template #modoEditarActivoPassword>
                <div class="list-group">
                    <div class="list-group-item list-group-item-action flex-column align-items-start">
                        <div class="row col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <div class="col-md-7 col-sm-7 col-7 col-lg-7 col-xl-7 resolMovil">
                                <div class="col-md-12 col-12 col-sm-12 col-lg-12 col-xl-12 resolMovil" style="padding-left: 0;">
                                    <label for="oldPassword" class="cabecera">{{'DATOSACCESO.PASSACTUAL' | translate }}</label>
                                    <input type="password" class="form-control" name="oldPassword"
                                        [(ngModel)]="oldPassword" id="passActual"><span class="loginIcon"
                                        (click)="cambiarIcono($event,'passActual','iconEyePerfil0')"><i
                                            class="fa fa-eye-slash" id="iconEyePerfil0"></i></span>
                                </div>

                                <div class="col-md-12 col-12 col-sm-12 col-lg-12 col-xl-12 resolMovil" style="padding-left: 0;">
                                    <label for="newPassword" class="label-top cabecera">{{'DATOSACCESO.PASSNUEVA' | translate }}</label>
                                    <input type="password" class="form-control " name="newPassword" id="passNew"
                                        [(ngModel)]="newPassword"><span class="loginIcon"
                                        (click)="cambiarIcono($event,'passNew','iconEyePerfil')"><i
                                            class="fa fa-eye-slash" id="iconEyePerfil"></i></span>
                                </div>

                                <div class="col-md-12 col-12 col-sm-12 col-lg-12 col-xl-12 resolMovil" style="padding-left: 0;">
                                    <label for="repPassword" class="label-top cabecera">{{'DATOSACCESO.PASSREPETIR' | translate }}</label>
                                    <input type="password" class="form-control" [(ngModel)]="repPassword"
                                        id="passRepeatPerfil"><span class="loginIcon"
                                        (click)="cambiarIcono($event,'passRepeatPerfil','iconEyePerfil1')"><i
                                            class="fa fa-eye-slash" id="iconEyePerfil1"></i></span>
                                </div>

                            </div>
                            <div class="col-md-5 col-sm-5 col-5 col-lg-5 col-xl-5">
                                <ul id="listaContacto">
                                    <li>
                                        <button type="button" class="btn btn-warning btn-lg"
                                            (click)="guardarOperacion('password')" style="margin-top: .3em;">{{'GLOBAL.GUARDAR' | translate }}&nbsp;</button>
                                    </li>
                                    <li>
                                        <!-- <span style="color:#2e1cf7;font-weight: bold;" id="editarPassword"
                                            (click)="editarPassword('cancelar')">Cancelar</span> -->
                                            <button type="button" class="btn btn-secondary btn-lg" style="font-weight: bold;" (click)="editarPassword('cancelar')">{{'GLOBAL.CANCELAR' | translate }}</button> 
                                    </li>
                                    <li style="padding:20px 0;">
                                        <small class="passOlvidada"
                                            (click)="abrirModalInformativo('requisitosPassword')">{{'DATOSACCESO.PASSCOMPLEJIDAD' | translate }}</small>
                                    </li>
                                  

                                </ul>
                                <!-- <div>
                                        <span style="color:#2e1cf7;font-weight: bold;" id="editarPassword" (click)="editarPassword('cancelar')">Cancelar</span>
                                    </div>
                                    <div>
                                        <small class="passOlvidada posicionamientoOlvide" (click)="abrirModal('requisitosPassword')">¿Cómo debe ser mi Contraseña?</small>
                                    </div>
                                    <div>
                                        <button type="button" class="btn btn-warning btn-lg posicionamientoBtn" style="margin-top:.75em;"
                                        (click)="guardarOperacion('password')">Guardar</button>
                                    </div> -->

                            </div>
                            <small id="error" *ngIf="errorPerfil">{{textoErrorPerfil}}</small>
                        </div>



                    </div>
                </div>
                <!-- <div class="form-group">
                        <label for="exampleInputEmail1">Email address</label>
                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email">
                        <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small>
                      </div> -->
            </ng-template>
        </div>
    </div>

    <div class="col-8 col-md-8 col-sm-8 col-lg-8 col-xl-8" 
        style="margin-top:1.5em;" *ngIf="datosDocumentos"> <!--offset-1 offset-md-1 offset-sm-1 offset-lg-1 offset-xl-1-->
        <div class="list-group reducirAncho">
            <div class="list-group-item list-group-item-action flex-column align-items-start">
                <div class="d-flex w-100 justify-content-between">
                    <h5 class="mb-1 cabecera" style="color:black;">{{'MISDOCUMENTOS.MISDOCUMENTOS' | translate }}</h5>
                </div>
                <div class="textoDocumentos">
                    <p style="font-size: .9rem;">
                        {{'MISDOCUMENTOS.MENSAJELISTADODOCUMENTOS' | translate }}
                        <a style="font-size:1em;"
                            href="mailto:clientes@ekiluzenergia.com?subject=Dudas y consultas área de clientes">clientes@ekiluzenergia.com</a>
                    </p>
                </div>
            </div>
            <!-- <div class="list-group">
                <div
                    class="list-group-item list-group-item-action flex-column align-items-start reducirAlturaMisDocumentos">
                    <p class="list-group-item-text" style="color:black;">Solicitud de nueva alta de suministro <a
                            class="enlace" style="float: right;" target="_blank" >Descargar plantilla</a></p>
                </div>
            </div> -->
            <div class="list-group">
                <div
                    class="list-group-item list-group-item-action flex-column justify-content-between align-items-start reducirAlturaMisDocumentos">
                    <p class="list-group-item-text cabecera" style="color:black;">{{'MISDOCUMENTOS.PROTECCIONDATOS' | translate }}<a
                            class="enlace" style="float: right;" target="_blank" [href]="translateService.currentLang == 'eu' ? 'https://ekiluz.blob.core.windows.net/ekiluzimagenes/doc/Compilado%20Formularios_eu.pdf' : 'https://ekiluz.blob.core.windows.net/ekiluzimagenes/doc/Compilado%20Formularios.pdf'">{{'MISDOCUMENTOS.DESCARGARPLANTILLA' | translate }}</a></p>
                </div>
            </div>
            <div class="list-group">
                <div
                    class="list-group-item list-group-item-action flex-column align-items-start justify-content-between reducirAlturaMisDocumentos">
                    <p class="list-group-item-text cabecera" style="color:black;">{{'MISDOCUMENTOS.DESESTIMIENTO' | translate }}<a class="enlace"
                            style="float: right;" target="_blank" [href]="translateService.currentLang == 'eu' ? 'https://ekiluz.blob.core.windows.net/ekiluzimagenes/doc/Plantilla%20Desistimiento_eu.pdf' : 'https://ekiluz.blob.core.windows.net/ekiluzimagenes/doc/Plantilla%20Desistimiento.pdf'" >{{'MISDOCUMENTOS.DESCARGARPLANTILLA' | translate }}</a></p>
                </div>
            </div>
            <!-- <div class="list-group">
                <div
                    class="list-group-item list-group-item-action flex-column align-items-start reducirAlturaMisDocumentos">
                    <p class="list-group-item-text" style="color:black;">Solicitud de cambio de cuenta bancaria <a
                            class="enlace" style="float: right;" target="_blank"  >Descargar plantilla</a></p>
                </div>
            </div> -->
            <div class="list-group">
                <div
                    class="list-group-item list-group-item-action flex-column align-items-start justify-content-between reducirAlturaMisDocumentos">
                    <p class="list-group-item-text cabecera" style="color:black;">{{'MISDOCUMENTOS.FACTURACION' | translate }}<a
                            class="enlace" style="float: right;" target="_blank" [href]="translateService.currentLang == 'eu' ? 'https://ekiluz.blob.core.windows.net/ekiluzimagenes/doc/Plantilla%20Cambio%20de%20Tipo%20de%20Facturaci%C3%B3n_eu.pdf' : 'https://ekiluz.blob.core.windows.net/ekiluzimagenes/doc/Plantilla%20Cambio%20de%20Tipo%20de%20Facturaci%C3%B3n.pdf'" >{{'MISDOCUMENTOS.DESCARGARPLANTILLA' | translate }}</a></p> 
                </div>
            </div>
            <!-- <div class="list-group">
                <div
                    class="list-group-item list-group-item-action flex-column align-items-start reducirAlturaMisDocumentos">
                    <p class="list-group-item-text" style="color:black;">Solicitud de baja definitiva <a class="enlace"
                            style="float: right;" target="_blank" >{{misDocumentos.descargarPlantilla}}</a></p>
                </div>
            </div> -->
            <div class="list-group">
                <div
                    class="list-group-item list-group-item-action flex-column align-items-start justify-content-between reducirAlturaMisDocumentos">
                    <p class="list-group-item-text cabecera" style="color:black;">{{'MISDOCUMENTOS.TITULAR' | translate }}<a class="enlace"
                            style="float: right;" target="_blank" [href]="translateService.currentLang == 'eu' ? 'https://ekiluz.blob.core.windows.net/ekiluzimagenes/doc/Plantilla%20Cambio%20de%20Titular_eu.pdf' : 'https://ekiluz.blob.core.windows.net/ekiluzimagenes/doc/Plantilla%20Cambio%20de%20Titular.pdf'">{{'MISDOCUMENTOS.DESCARGARPLANTILLA' | translate }}</a></p>
                </div>
            </div>
        </div>
    </div>

</div>

<div class=" col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 noPaddingMarginRow">



</div>


