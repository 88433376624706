import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {

  private requests:HttpRequest<any>[] = [];
  constructor() { }
  
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const token: string = sessionStorage.getItem('userToken');
    //también se podría obtener del authService
    // const modifiedRequest = req.clone({headers: req.headers.append('Access-Control-Allow-Origin', '*')});
    // this.requests.push(modifiedRequest);
    // let request = req;
    // let authToken = req.headers.append('Authorization', token);
    if (token) {
      req = req.clone({
        headers: req.headers.append('Access-Control-Allow-Origin', '*')
        .set('Authorization', token)
      });
    } else {
      req = req.clone({
        headers: req.headers.append('Access-Control-Allow-Origin', '*'),
        // setHeaders: {
          
        // }
      });
    }
    return next.handle(req);
}

}
