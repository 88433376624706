import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { Contrato } from 'src/app/Models/contrato.model';
import { ContratoService } from 'src/app/Services/contrato.service';
import { HeaderNavService } from 'src/app/Services/headerNavService';
import { LoginService } from 'src/app/Services/login.service';
import { UtilsService } from 'src/app/utils/utilsService';

declare var $;
@Component({
  selector: 'app-contratos',
  templateUrl: './contratos.component.html',
  styleUrls: ['./contratos.component.scss']
})
export class ContratosComponent implements OnInit {

  contratos: Contrato[];

  existenContratos: boolean = true;

  tokenExpirado: boolean = false;

  //PAGINATION
  page = 1;
  count;
  tableSize = 5;
  tableSizes = [5, 10, 15, 20];

  mensajeToast1: string 
  mensajeToast2: string

  // arrayPrueba = [];
  constructor(private utilsService: UtilsService, private contratoService: ContratoService, private spinner: NgxSpinnerService,
    private toastr: ToastrService, private loginService: LoginService, private headerNavService: HeaderNavService, private router: Router, private translateService: TranslateService) { }

  ngOnInit(): void {
    // $("#containerFooter").hide();  
    this.utilsService.existeUsuarioLogueado();

    if (this.utilsService.existeToken()) {
      this.cargarContratos();
    } else {
      this.headerNavService.visibilidadHeader(false);
    }
  }

  ngAfterViewInit() {
    // setTimeout(() => {
    // $("#containerFooter").show();  
    // }, 600);
  }

  /*PAGINATION METHODS*/
  onTableDataChange(event) {
    this.page = event;
  }

  onTableSizeChange(event): void {
    this.tableSize = event.target.value;
    this.page = 1;
  }

  ngOnDestroy() {
    //  $("#containerFooter").hide();  
  }

  cargarContratos() {
    this.spinner.show();
    this.contratoService.getContratos().subscribe(
      (response: any) => {
        console.log(response);
        if (response && response.records) {
          this.contratos = response.records;
          // this.contratos = [];

          if (this.contratos.length == 0) {
            this.existenContratos = false;
          }
        }
        if (response && response.totalSize) {
          this.count = response.totalSize;
        }
        setTimeout(() => {
          this.spinner.hide();
        }, 500);
      },
      err => {
        console.error("Ocurrió un error: " + err.message);
        let errorCode = err.status;
        // let mensaje;
        switch (errorCode) {
          case 500:
            this.translateService.stream("MENSAJES.ERRORSERVER").subscribe(resul => {
              this.mensajeToast1 = resul
              // mensaje = resul
            });
            break;
          case 0: //problema de conexion con el backEnd
            this.translateService.stream("MENSAJES.ERRORINTENTARDENUEVO").subscribe(resul => {
              this.mensajeToast1 = resul
              // mensaje = resul
            });
            break;
          case 401: //problema de token expirado
            this.translateService.stream("MENSAJES.SESIONCADUCADA").subscribe(resul => {
              this.mensajeToast1 = resul
              // mensaje = resul
            });
            this.tokenExpirado = true;
            this.loginService.setLogoutSession();
            this.headerNavService.visibilidadHeader(false);
            break;
        }
        setTimeout(() => {
          this.spinner.hide();
          if (errorCode == 401) {
            this.toastr.error('', this.mensajeToast1, {
              disableTimeOut: true,
            });
          } else {
            this.toastr.error(this.mensajeToast1);
          }
        }, 500);

        if (this.tokenExpirado) {
          this.router.navigate(['login']);
        }
      }
    );
  }



  descargarContrato(url: string, numeroContrato: string) {
    this.contratoService.descargarContrato(url).subscribe(
      (response: any) => {
        console.log(response);
        this.utilsService.generarPDF(response, numeroContrato, '', '');
      },
      err => {
        console.error("Ocurrió un error: " + err.message);
        let errorCode = err.status;
        // let mensaje
        if (errorCode == 401) {
          this.tokenExpirado = true;
          this.loginService.setLogoutSession();
          this.headerNavService.visibilidadHeader(false);
          this.translateService.stream("MENSAJES.SESIONCADUCADA").subscribe(resul => {
            this.mensajeToast2
            // mensaje = resul
          });
          this.toastr.error('', this.mensajeToast2, {
            disableTimeOut: true,
          });
        } else {
          this.translateService.stream("MENSAJES.ERRORDESCARGACONTRATO").subscribe(resul => {
            this.mensajeToast2
            // mensaje = resul
          });
          this.toastr.error(this.mensajeToast2);
        }
        if (this.tokenExpirado) {
          this.router.navigate(['login']);
        }
      }
    )
  }

}



