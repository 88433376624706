import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { dialogModalLogOut, HeaderComponent } from './Components/header/header.component';
import { FacturasComponent } from './Components/facturas/facturas.component';
import { ContratosComponent } from './Components/contratos/contratos.component';
import { ContactoComponent, dialogModal } from './Components/contacto/contacto.component';
import { dialogModalLogin } from './Components/login/login.component';
import { LoginComponent } from './Components/login/login.component';
import { FooterComponent } from './Components/footer/footer.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgxPaginationModule } from 'ngx-pagination';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import {MatDialogModule} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import { ToastrModule } from 'ngx-toastr';
import { NgxSpinnerModule } from "ngx-spinner";
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { OAuthModule } from 'angular-oauth2-oidc';
import { AuthInterceptorService } from './Services/auth-interceptor.service';
import {MatInputModule} from '@angular/material/input';
import { dialogModalOlvidePassword, OlvidoPassComponent } from './Components/olvido-pass/olvido-pass.component';
import { CrosscheckComponent } from './Components/crosscheck/crosscheck.component';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FacturasComponent,
    ContratosComponent,
    ContactoComponent,
    LoginComponent,
    FooterComponent,
    dialogModal,
    dialogModalLogin,
    dialogModalLogOut,
    OlvidoPassComponent,
    dialogModalOlvidePassword,
    CrosscheckComponent

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    FontAwesomeModule,
    NgxPaginationModule,
    BrowserAnimationsModule,
    FormsModule,
    MatDialogModule,
    MatIconModule,
    ToastrModule.forRoot(),
    HttpClientModule,
    OAuthModule.forRoot(),
    NgxSpinnerModule,
    MatInputModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    })
  ],  
  exports:[NgxPaginationModule],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptorService,
    multi: true
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
