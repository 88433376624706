import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { Factura } from 'src/app/Models/factura.model';
import { FacturaService } from 'src/app/Services/factura.service';
import { HeaderNavService } from 'src/app/Services/headerNavService';
import { LoginService } from 'src/app/Services/login.service';
import { UtilsService } from 'src/app/utils/utilsService';

declare var $;
@Component({
  selector: 'app-facturas',
  templateUrl: './facturas.component.html',
  styleUrls: ['./facturas.component.scss']
})
export class FacturasComponent implements OnInit {


  // tableCols = ["PERIODO","NUMERO","CUPS","ESTADO","IMPORTE",""];
  // tableCols = [
  //   { "key": "PERIODO", "label": "Periodo" },
  //   { "key": "NUMERO", "label": "Nº Factura" },
  //   { "key": "CUPS", "label": "CUPS" },
  //   { "key": "ESTADO", "label": "Estado" },
  //   { "key": "IMPORTE", "label": "Importe" },
  //   { "key": "", "label": "" }];



  facturas: Factura[];

  existenFacturas: boolean = true;

  //PAGINATION
  page = 1;
  count;
  tableSize = 5;
  tableSizes = [5, 10, 15, 20];

  tokenExpirado: boolean = false;

  mensajeToast1: string
  mensajeToast2: string

  constructor(private utilsService: UtilsService, private facturasService: FacturaService, private spinner: NgxSpinnerService,
    private toastr: ToastrService, private router: Router, private loginService: LoginService, private headerNavService: HeaderNavService, public translateService: TranslateService) { }

  ngOnInit(): void {
    // $("#containerFooter").hide();  
    console.log(this.router.url);

    this.utilsService.existeUsuarioLogueado();
    if (this.utilsService.existeToken()) {
      this.cargarFacturas();
    } else {
      this.headerNavService.visibilidadHeader(false);
    }
  }




  ngAfterViewInit() {
    // setTimeout(() => {
    //   $("#containerFooter").show();  

    //   }, 600);
  }

  cargarFacturas() {
    this.spinner.show();
    this.facturasService.getFacturas().subscribe(
      (response: any) => {
        console.log(response);
        if (response && response.records) {
          this.facturas = this.utilsService.ordenarFacturasPorFecha(response.records);

          //  this.facturas = response.records;
          //  this.facturas = [];

          if (this.facturas.length == 0) {
            this.existenFacturas = false;
          }
        }
        if (response && response.totalSize) {
          this.count = response.totalSize;
          // this.count = this.facturas.length;
        }
        setTimeout(() => {
          this.spinner.hide();
        }, 500);
      },
      err => {
        console.error("Ocurrió un error: " + err.message);
        let errorCode = err.status;
        // let mensaje;
        switch (errorCode) {
          case 500:
            this.translateService.stream("MENSAJES.ERRORSERVER").subscribe(resul => {
              this.mensajeToast1 = resul
              // mensaje = resul
            });
            break;
          case 0: //problema de conexion con el backEnd
            this.translateService.stream("MENSAJES.ERRORINTENTARDENUEVO").subscribe(resul => {
              this.mensajeToast1 = resul
              // mensaje = resul
            });
            break;
          case 401: //problema de token expirado
            this.translateService.stream("MENSAJES.SESIONCADUCADA").subscribe(resul => {
              this.mensajeToast1 = resul
              // mensaje = resul
            });
            this.tokenExpirado = true;
            this.loginService.setLogoutSession();
            this.headerNavService.visibilidadHeader(false);
            break;
        }
        setTimeout(() => {
          this.spinner.hide();
          if (errorCode == 401) {
            this.toastr.error('', this.mensajeToast1, {
              disableTimeOut: true,
            });
          } else {
            this.toastr.error(this.mensajeToast1);
          }
        }, 500);

        if (this.tokenExpirado) {
          this.router.navigate(['login']);
        }
      }

    );

  }



  descargaFactura(url: string, nombreFactura: string, startDate: string, endDate: string) {
    this.facturasService.descargarFactura(url).subscribe(
      (response: any) => {
        console.log(response);
        this.utilsService.generarPDF(response, nombreFactura, startDate, endDate);
      },
      err => {
        console.error("Ocurrió un error: " + err.message);
        let errorCode = err.status;
        // let mensaje
        if (errorCode == 401) {
          this.tokenExpirado = true;
          this.loginService.setLogoutSession();
          this.headerNavService.visibilidadHeader(false);
          this.translateService.stream("MENSAJES.SESIONCADUCADA").subscribe(resul => {
            this.mensajeToast2 = resul
            // mensaje = resul
          });
          this.toastr.error('', this.mensajeToast2, {
            disableTimeOut: true,
          });
        } else {
          this.translateService.stream("MENSAJES.ERRORDESCARGAFACTURA").subscribe(resul => {
            this.mensajeToast2 = resul
            // mensaje = resul
          });
          this.toastr.error(this.mensajeToast2);
        }
        if (this.tokenExpirado) {
          this.router.navigate(['login']);
        }
      }
    )
  }




  calculateStyle(estado: string) {
    if (estado == "Pendiente") {
      return 'colorPendiente';
    } else { //PAGADA
      return 'colorPagada';
    }
  }



  /*PAGINATION METHODS*/
  onTableDataChange(event) {
    this.page = event;
  }

  onTableSizeChange(event): void {
    this.tableSize = event.target.value;
    this.page = 1;
  }
  ngOnDestroy() {
    // $("#containerFooter").hide();  
  }
}
