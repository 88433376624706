<div class="row white  col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 noPadding " id="principalLogin">
  <div class="row navBarHeight col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 noPaddingMarginRow">
    <div class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 alignHeader" id="containerLogoLogin">
      <img src="assets/img/logo-ekiluz-energia.svg" id="logoEkiluzEnergiaLogin" class="separationTop"
        (click)="recargarPagina()">
    </div>
  </div>

  <div class=" row  col-9 col-sm-9 col-md-9 col-lg-9 col-xl-9 noPaddingMarginRow" style="justify-content: right;" id="contactoLogin">
    <div class="row col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12 noPaddingMarginRow" style="padding-top:25px;"
      id="maxAnchoLogin">
      <div class="col-12 col-md-10 col-sm-10 col-lg-10 col-xl-10" style="text-align: -webkit-right;">
        <span style="font-size: .85rem;">{{"LOGIN.PROBLEMASACCESO" | translate}}</span>
        <a style="font-size:.85rem;" id="contactanos" href="tel:+34900293741">{{"LOGIN.CONTACTANOS" | translate}}</a>
      </div>

      <div class="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2 noPaddingMarginRow" id="selectorIdiomaContainer">
        <div ngbDropdown>
          <button class="btn selectorIdioma" id="dropdownBasicIdioma"
            ngbDropdownToggle>
            <div id="contenedorImgTextoLogin">
              <span style="font-size:.85rem;" id="selectorLogin">{{'SELECTOR.TITLE' | translate }}</span>
            </div>
          </button>
          <div ngbDropdownMenu aria-labelledby="dropdownBasicIdioma">
            <button ngbDropdownItem (click)="utilsService.actualizarIdiomaNoLogueado('es')"
              [ngClass]="translateService.currentLang == 'es' ? 'idiomaSeleccionado' : ''">{{'SELECTOR.SPANISH' | translate
              }} <i class="fa fa-check" *ngIf="translateService.currentLang === 'es'"></i></button>
            <button ngbDropdownItem (click)="utilsService.actualizarIdiomaNoLogueado('eu')"
              [ngClass]="translateService.currentLang == 'eu' ? 'idiomaSeleccionado' : ''">{{'SELECTOR.EUSKERA' | translate
              }} <i class="fa fa-check" *ngIf="translateService.currentLang === 'eu'"></i></button>
          </div>
        </div>
      </div>
    </div>
  </div>

  


  <div class=" col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12  menuBackground "
    *ngIf="mostrarLogin; else mostrarRegistro">
    <div class="row col-10 col-sm-10 col-md-5 col-lg-5 col-xl-5 mx-auto borderBottom" style="background-color: white;"
      id="divPadding">
      <div class="col-12 col-sm-12 col-xl-12 col-lg-12 col-md-12 noPaddingMarginRow" style="padding:0px;">
        <h4 style="color:black;">{{"LOGIN.BIENVENIDO" | translate}}</h4>
      </div>
      <p class="margenFormulario" style="font-size: .8rem;">{{"LOGIN.ACCEDERACLIENTESCONTRATO" | translate}}</p>
      <form autocomplete="on" style="width:100%;">
        <div class="form-group largeForm">
          <mat-form-field class="anchoForm">
            <mat-label>{{"LOGIN.INTRODUCEMAIL" | translate}}</mat-label>
            <input type="email" id="emailAcceso" name="mailAccesoLogin" [(ngModel)]="mailLogin" matInput
              [placeholder]="'LOGIN.INTRODUCEMAIL' | translate">
          </mat-form-field>
          <!-- <input type="email" class="form-control" id="emailAcceso" [(ngModel)]="mailLogin" placeholder="Introduce tu e-mail"> -->
        </div>



        <div class="form-group largeForm">
          <mat-form-field class="anchoForm">
            <mat-label>{{"LOGIN.INTRODUCEPASSWORD" | translate}}</mat-label>
            <input type="password" id="passwordAcceso" name="mailAccesoPassword" [(ngModel)]="passLogin" matInput
              [placeholder]="'LOGIN.INTRODUCEPASSWORD' | translate">
            <span class="loginIcon" (click)="cambiarIcono($event,'passwordAcceso','iconEyeLogin')"><i
                class="fa fa-eye-slash" id="iconEyeLogin"></i></span>
          </mat-form-field>
          <!-- <input type="password" class="form-control" id="passwordAcceso" [(ngModel)]="passLogin" placeholder="Introduce tu contraseña">
            <span class="loginIcon" (click)="cambiarIcono($event,'passwordAcceso','iconEyeLogin')"><i class="fa fa-eye-slash" id="iconEyeLogin"></i></span> -->

          <div style="margin-top:.5em;">
            <small class="passOlvidada"
              (click)="pantallaVariableRegistro('passwordOlvidada')">{{"LOGIN.OLVIDADOPASSWORD" | translate}}</small>
          </div>
        </div>
      </form>
      <button type="button" class="btn btn-warning btn-lg right bigBtn"
        style="font-weight: bold;margin-top: .75em;margin-bottom: .75em;" (click)="procederLogin()">{{"LOGIN.ACCEDER" |
        translate}}</button>
      <small id="error" *ngIf="errorLogin">{{textoErrorLogin}}</small>

      <div class="bordeInferior">

      </div>
      <div class="registro">
        <p style="line-height: 0; margin-bottom: 0.75em !important;font-size: .75rem;">{{"LOGIN.NOCUENTA" | translate}}
        </p>
        <small class="passOlvidada" (click)="pantallaVariableRegistro('registroUsuario')">{{"LOGIN.REGISTRATE" |
          translate}}</small>
      </div>
    </div>
  </div>
</div>

<ng-template #mostrarRegistro>
  <div class="row col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 noPadding "
    *ngIf="procesoRegistroCorrecto != true; else registroCorrecto">
    <div class=" col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12  menuBackground ">
      <div class="row col-10 col-sm-10 col-md-5 col-lg-5 col-xl-5 mx-auto"
        style="background-color: white;padding:88px;margin-top:2em;margin-bottom: 1.5em;" id="divPaddingRegistro">
        <div class="col-12 col-sm-12 col-xl-12 col-lg-12 col-md-12 noPaddingMarginRow" style="padding:0px;">
          <h4 style="color:black;">{{textoVariante}}</h4>
        </div>
        <p style="font-size: .8rem;">{{textoSubVariante}}</p>
        <div class="form-group largeForm" *ngIf="tipoPantalla =='registroUsuario'">
          <!-- <input type="text" class="form-control" [(ngModel)]="dni" id="registroMail"  placeholder="Introduce tu DNI / NIF"> -->
          <mat-form-field class="anchoForm">
            <mat-label>{{"LOGIN.INTRODUCEDNI" | translate}}</mat-label>
            <input type="text" [(ngModel)]="dni" id="registroMail" matInput
              [placeholder]="'LOGIN.INTRODUCEDNI' | translate">
          </mat-form-field>
        </div>
        <div class="form-group largeForm" *ngIf="tipoPantalla == 'registroUsuario'">
          <!-- <input type="text" class="form-control" [(ngModel)]="numContrato" id="registroPass" placeholder="Introduce tu número de contrato"> -->
          <mat-form-field class="anchoForm">
            <mat-label>{{"LOGIN.INTRODUCECONTRATO" | translate}}</mat-label>
            <input type="text" [(ngModel)]="numContrato" id="registroMail" matInput
              [placeholder]="'LOGIN.INTRODUCECONTRATO' | translate">
            <mat-hint class="passOlvidada" style="cursor: pointer" (click)="abrirModal('registro','','')">
              {{"LOGIN.DONDEENCONTRAR"| translate}}</mat-hint>
          </mat-form-field>
        </div>
        <div class="form-group largeForm" *ngIf="tipoPantalla == 'passwordOlvidada'">
          <mat-form-field class="anchoForm">
            <mat-label>{{"LOGIN.INTRODUCEMAIL"| translate}}</mat-label>
            <input type="email" [(ngModel)]="emailOlvidePassword" id="registroMail" matInput
              [placeholder]="'LOGIN.INTRODUCEMAIL'| translate">
          </mat-form-field>
        </div>
        <button *ngIf="tipoPantalla == 'registroUsuario'" type="button" class="btn btn-warning btn-lg right bigBtn"
          style="font-weight: bold; margin-top: 1rem;" (click)="procederRegistro()">{{"GLOBAL.CONTINUAR"|
          translate}}</button>
        <button *ngIf="tipoPantalla == 'passwordOlvidada'" type="button" class="btn btn-warning btn-lg right bigBtn"
          style="font-weight: bold;" (click)="enviarEmail()">{{"LOGIN.ENVIARCORREO"| translate}}</button>
        <small id="error" *ngIf="error">{{textoError}}</small>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #registroCorrecto>
  <div class="row col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 noPadding "
    *ngIf="registroMailCorrecto != true; else mailCorrecto">
    <div class=" col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12  menuBackground ">
      <div class="row col-10 col-sm-10 col-md-5 col-lg-5 col-xl-5 mx-auto" id="divPaddingRegistroUsuario"
        style="background-color: white;padding:88px;margin-top:2em;margin-bottom: 1.5em;">
        <div class="col-12 col-sm-12 col-xl-12 col-lg-12 col-md-12 noPaddingMarginRow" style="padding:0px;">
          <h4 style="color:black;">{{textoVariante}}</h4>
        </div>
        <p style="font-size: .8rem;">{{textoSubVariante}}</p>
        <p style="font-size: .8rem;">{{"LOGIN.USARMAIL"| translate}}</p>
        <div class="form-group largeForm">
          <mat-form-field class="anchoForm">
            <mat-label>{{"LOGIN.INTRODUCEMAIL"| translate}}</mat-label>
            <input type="email" id="emailAcceso" [(ngModel)]="email" matInput
              [placeholder]="'LOGIN.INTRODUCEMAIL' | translate">
          </mat-form-field>
          <!-- <input type="email" class="form-control" [(ngModel)]="email" id="exampleInputEmail1"  placeholder="Introduce tu dirección de e-mail"> -->
        </div>
        <button type="button" class="btn btn-warning btn-lg right bigBtn" style="font-weight: bold;"
          (click)="registrarMail()">{{"LOGIN.REGISTRAME"| translate}}</button>
        <small id="error" *ngIf="errorMail">{{textoErrorMail}}</small>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #mailCorrecto>
  <div class="row col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 noPadding ">
    <div class=" col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12  menuBackground ">
      <div class="row col-10 col-sm-10 col-md-5 col-lg-5 col-xl-5 mx-auto" id="divPaddingRegistroPassword"
        style="background-color: white;padding:88px;margin-top:2em;margin-bottom: 1.5em;">
        <div class="col-12 col-sm-12 col-xl-12 col-lg-12 col-md-12 noPaddingMarginRow" style="padding:0px;">
          <h4 style="color:black;">{{textoVariante}}</h4>
        </div>
        <p style="font-size: .8rem;">{{"LOGIN.ACCEDERACLIENTESPASSWORD"| translate}}</p>
        <div class="form-group largeForm">
          <mat-form-field class="anchoForm">
            <mat-label>{{"LOGIN.INTRODUCEPASSWORD"| translate}}</mat-label>
            <input type="password" [(ngModel)]="password" id="pass" matInput
              [placeholder]="'LOGIN.INTRODUCEPASSWORD' | translate"><span class="loginIcon"
              (click)="cambiarIcono($event,'pass','iconEye')"><i class="fa fa-eye-slash" id="iconEye"></i></span>
          </mat-form-field>
          <!-- <input type="password" class="form-control" [(ngModel)]="password" id="pass"  placeholder="Introduce tu contraseña" ><span class="loginIcon" (click)="cambiarIcono($event,'pass','iconEye')"><i class="fa fa-eye-slash" id="iconEye"></i></span> -->
        </div>
        <div class="form-group largeForm">
          <mat-form-field class="anchoForm">
            <mat-label>{{"LOGIN.REPETIRPASSWORD"| translate}}</mat-label>
            <input type="password" [(ngModel)]="repeticionPassword" id="passRepeat" matInput
              [placeholder]="'LOGIN.REPETIRPASSWORD' | translate"><span class="loginIcon"
              (click)="cambiarIcono($event,'passRepeat','iconEye1')"><i class="fa fa-eye-slash"
                id="iconEye1"></i></span>
          </mat-form-field>
          <!-- <input type="password" class="form-control" [(ngModel)]="repeticionPassword" id="passRepeat"  placeholder="Repite tu contraseña"><span class="loginIcon" (click)="cambiarIcono($event,'passRepeat','iconEye1')"><i class="fa fa-eye-slash" id="iconEye1"></i></span> -->
          <div style="margin-top:.5em;">
            <small class="passOlvidada" (click)="abrirModal('infoPassword','','')">{{"LOGIN.COMOPASSWORD"|
              translate}}</small>
          </div>
        </div>
        <button type="button" style="font-weight: bold;" class="btn btn-warning btn-lg right bigBtn"
          (click)="registrarPassword()">{{"LOGIN.ESTABLECERPASSWORD"| translate}}</button>
        <small id="error" *ngIf="errorPassword">{{textoErrorPassword}}</small>

      </div>
    </div>
  </div>
</ng-template>




<!-- <i class="fas fa-eye"></i> -->