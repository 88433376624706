import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContactoComponent } from './Components/contacto/contacto.component';
import { ContratosComponent } from './Components/contratos/contratos.component';
import { CrosscheckComponent } from './Components/crosscheck/crosscheck.component';
import { FacturasComponent } from './Components/facturas/facturas.component';
import { LoginComponent } from './Components/login/login.component';
import { OlvidoPassComponent } from './Components/olvido-pass/olvido-pass.component';
// import { PrincipalComponent } from './Components/principal/principal.component';


const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  // { path: 'principal', component: PrincipalComponent, data: {animation: 'Principal'}},
  { path: 'facturas', component: FacturasComponent, data: {animation: 'Facturas'}},
  { path: 'contratos', component: ContratosComponent, data: {animation: 'Contratos'} },
  { path: 'contacto', component: ContactoComponent, data: {animation:'Contacto'}},
  { path: 'login', component: LoginComponent},
  { path: 'resetPassword', component: OlvidoPassComponent},
  { path: 'descargarDocumento', component: CrosscheckComponent}


];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash:true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
