<div *ngIf="tipoVentanaModal == 'registro'; else password">
  <button mat-icon-button class="close-button" [mat-dialog-close]="true">
    <mat-icon class="close-icon" >close</mat-icon>
  </button>
  <!-- <i class="fa fa-user" id="iconUser"></i> -->
<h2 style="color:black;" mat-dialog-title>{{"MODALVERIFICACION.DONDENUMCONTRATO" | translate}}</h2>
<div mat-dialog-content>
  <p class="parrafoModal">
    {{"MODALVERIFICACION.ENCONTRARNUMCONTRATO" | translate}}
  </p>
</div>
<div mat-dialog-actions>
    <button type="button" class="btn btn-warning btn-lg posicionamientoBtn" style="margin-top:.75em;margin-bottom:.5em;width:100%;font-weight: bold;"  [mat-dialog-close] = "true">{{"MODALVERIFICACION.ENTENDIDO" | translate}}</button> <!--(click)="guardarOperacion()"-->
  </div>

</div>

<ng-template #password>
  <div *ngIf="tipoVentanaModal == 'infoPassword'; else exitoCreacion">
    <button mat-icon-button class="close-button" [mat-dialog-close]="true">
      <mat-icon class="close-icon" >close</mat-icon>
    </button>
    <!-- <i class="fa fa-user" id="iconUser"></i> -->
  <h2 style="color:black;" mat-dialog-title>{{"MODALVERIFICACION.COMOPASSWORD" | translate}}</h2>
  <div mat-dialog-content>
    <p class="parrafoModal">
      {{"MODALVERIFICACION.FORMATOPASSWORD" | translate}}
    </p>
    <ul id="listaModal">
      <li>{{"MODALVERIFICACION.MAYUSCULA" | translate}}</li>
      <li>{{"MODALVERIFICACION.MINUSCULA" | translate}}</li>
      <li>{{"MODALVERIFICACION.NUMERO" | translate}}</li>
      <li>{{"MODALVERIFICACION.SIMBOLO" | translate}} {{simbolosEspeciales}}</li>
      <br>
      <li>{{"MODALVERIFICACION.EJEMPLO" | translate}}</li>
    </ul>
  </div>
  <div mat-dialog-actions>
      <button type="button" class="btn btn-warning btn-lg posicionamientoBtn" style="margin-top:.75em;margin-bottom:.5em;width:100%; font-weight: bold;"  [mat-dialog-close] = "true">{{"GLOBAL.CONTINUAR" | translate}}</button> <!--(click)="guardarOperacion()"-->
    </div>
  
  </div>
</ng-template>

<ng-template #exitoCreacion>
  <div>
    <button mat-icon-button class="close-button" [mat-dialog-close]="true">
      <mat-icon class="close-icon" >close</mat-icon>
    </button>
    <i class="fa fa-user" id="iconUser"></i>
  <h2 style="color:black;" mat-dialog-title>{{tituloModal}}</h2>
  <div mat-dialog-content>
    <p class="parrafoModal">
      {{textoModal}}
    </p>
  </div>
  <div mat-dialog-actions>
     <!--routerLink="/login"--> <button type="button" (click)="iniciarSesion()" class="btn btn-warning btn-lg posicionamientoBtn" style="margin-top:.75em;margin-bottom:.5em;width:100%;font-weight: bold;"  [mat-dialog-close] = "true">{{"MODALVERIFICACION.INICIARAREACLIENTES" | translate}}</button> <!--(click)="guardarOperacion()"-->
    </div>
  
  </div>
</ng-template>