<div class="noPaddingMarginRow totalColorHe">
    <div class=" noPaddingMarginRow ">
        <ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#fff" type = "ball-spin" [fullScreen] = "true"><p style="color: white" > {{"GLOBAL.CARGANDO" | translate}}... </p></ngx-spinner>
         <headerNav></headerNav>
        <div [@routeAnimations]="animation && animation.activatedRouteData && animation.activatedRouteData['animation']">
            <router-outlet #animation="outlet"></router-outlet>
        </div>
        <div style="height:5rem;position: relative; bottom: 0%;" > 
            <footer></footer> 
        </div>
    </div>
    <button class="btn  btn-xs" id="arrowUp" (click)="scrollToHeight0()">
        <i class="fa fa-chevron-up" style="color:white;"></i>
    </button>
</div>

