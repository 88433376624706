import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Login } from '../Models/login.model';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  urlApi:any =  environment.url + "/login" ;

  
  // httpOptions = {
  //   headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  // };

  public userData;
  public userToken;
  public objectUserLogin = null;
  
  goIniciarSesion$ = new EventEmitter<boolean>();

  constructor(private http: HttpClient) { }


  public setAuthToken(userToken) {
    this.objectUserLogin = userToken;
  }
  public getAuthToken() {
    return this.objectUserLogin;
  }

  public setUserSession(dataUser, tokenData,  time?) {
    this.userData = dataUser;
    this.userToken = tokenData;
    sessionStorage.setItem('userSession', JSON.stringify(this.userData));
    sessionStorage.setItem('userToken', this.userToken);
    const initialDate = time ? time : Math.floor(new Date().getTime() / 1000);
    // Sumo para obtener la hora a mayores (1000 * 60 para obtener minutos) y (minuto * 30 para obtener la media hora)
    // initialDate += 1000 * 60 * 30;
    localStorage.setItem(sessionStorage.getItem('userToken') + 'TimeExpired', initialDate.toString());
  }

   // Eliminación de la sesión del usuario
   public setLogoutSession() {
    console.log('Se hace logout de la sesión');
    sessionStorage.removeItem('userToken');
    sessionStorage.removeItem('userSession');
    localStorage.removeItem(sessionStorage.getItem('userToken') + 'TimeExpired');
    // rediriga al login
  }

  postUsuario(login: Login):Observable<any>{
    return this.http.post<Login>(this.urlApi, login ,{observe: 'response'});
  }


  navegarLogin(flag: boolean) {
    this.goIniciarSesion$.emit(flag);
  }
}
