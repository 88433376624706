import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { HeaderNavService } from '../../Services/headerNavService';
import { UtilsService } from '../../utils/utilsService';

import { OAuthService } from 'angular-oauth2-oidc';
// import {authCodeFlowConfig} from '../../Config/authCodeFlowConfig';
import { FooterService } from 'src/app/Services/footerService';
// import { EventEmitterService } from 'src/app/Services/event-emitter.service';
import { Subscription } from 'rxjs';
import { LoginService } from 'src/app/Services/login.service';
import { Login } from 'src/app/Models/login.model';
import jwt_decode from 'jwt-decode';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import { NgxSpinnerService } from "ngx-spinner";
import { UsuarioService } from 'src/app/Services/usuario.service';
import { Usuario } from 'src/app/Models/usuario.model';
import { TranslateService } from '@ngx-translate/core';

declare var $;
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  mostrarLogin: boolean = true;
  mostrarRegistro: boolean = false;
  textoVariante: string;
  textoSubVariante: string;
  tipoPantalla: string;
  textoConfirmacionORecuperacion: string;
  textoConfirmacionORecuperacionSmall: string;

  procesoRegistroCorrecto: boolean = false;
  registroMailCorrecto: boolean = false;
  registroPassCorrecto: boolean = false;

  //variables ngModel registro
  numContrato: string;
  dni: string;
  email: string;
  password: string;
  repeticionPassword: string;

  //variables ngModel login
  mailLogin: string;
  passLogin: string;

  //Auth
  token: any;
  clickLogOut: Subscription;

  responseSession: any;

  nombreUsuario: string;

  emailOlvidePassword: string;

  error: boolean = false;

  textoError: string;

  errorLogin: boolean = false;

  textoErrorLogin: string;

  errorPassword: boolean = false;

  textoErrorPassword: string;

  errorMail: boolean = false;

  textoErrorMail: string;

  mensajeToast1: string
  mensajeToast2: string

  constructor(private headerNavService: HeaderNavService, private footerService: FooterService, public dialog: MatDialog, public utilsService: UtilsService,
    private toastr: ToastrService, private oAuthService: OAuthService, private loginService: LoginService,
    private router: Router, private spinner: NgxSpinnerService, private userService: UsuarioService, public translateService: TranslateService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.utilsService.cargarIdiomaNoLogueado(params.language)
    });

    let token = sessionStorage.getItem('userToken');

    if (token) { //si el usuario esta logueado no puede volver al login
      this.router.navigate(['contacto']);
      // this.toastr.warning("Usted ya ha iniciado sesión");
    }

    this.headerNavService.visibilidadMenu(false);
    // this.headerNavService.visibilidadDirUsuario(false);
    this.headerNavService.visibilidadUsuarioLogueado(false);
    // this.footerService.visibilidadFooter(false);

    this.loginService.goIniciarSesion$.subscribe(filtroServiceBoolean => {
      this.mostrarLogin = filtroServiceBoolean;
    });


  }

  recargarPagina() {
    this.router.navigate(['login']);
    window.location.reload();
  }


  pantallaVariableRegistro(tipoPantalla: string) {
    if (tipoPantalla == "registroUsuario") {
      this.mostrarLogin = false;
      this.mostrarRegistro = true;
      this.translateService.stream("MENSAJES.REGISTRATE").subscribe(resul => {
        this.textoVariante = resul
      });
      this.translateService.stream("MENSAJES.BIENBENIDOAREACREAR").subscribe(resul => {
        this.textoSubVariante = resul
      });
      this.tipoPantalla = tipoPantalla;
      this.translateService.stream("MENSAJES.CUENTADISPONIBLE").subscribe(resul => {
        this.textoConfirmacionORecuperacion = resul
      });
      this.translateService.stream("MENSAJES.DESDEAHORA").subscribe(resul => {
        this.textoConfirmacionORecuperacionSmall = resul
      });
    } else if (tipoPantalla == "passwordOlvidada") {
      this.mostrarLogin = false;
      this.mostrarRegistro = true;
      this.translateService.stream("MENSAJES.RECUPEREPASSWORD").subscribe(resul => {
        this.textoVariante = resul
      });
      this.translateService.stream("MENSAJES.BIENBENIDOAREARECUPERAR").subscribe(resul => {
        this.textoSubVariante = resul
      });
      this.tipoPantalla = tipoPantalla;
      this.translateService.stream("MENSAJES.MAILCAMBIOPASSWORD").subscribe(resul => {
        this.textoConfirmacionORecuperacion = resul
      });
      this.translateService.stream("MENSAJES.ACCIONNOREALIZADA").subscribe(resul => {
        this.textoConfirmacionORecuperacionSmall = resul
      });
    }
  }



  abrirModal(accion: string, titulo: string, texto: string) {
    const dialogRef = this.dialog.open(dialogModalLogin, {
      data: { info: accion, titulo: titulo, texto: texto }
    });

    dialogRef.afterOpened().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      //GESTIONES DEL MODAL DESPUES DE SU APERTURA
      /*Impedimos que se pueda cerrar el modal y sea fija la navegación al area cliente*/
      if (dialogRef.componentInstance.data.info == "registroUsuario") {
        dialogRef.disableClose = true;
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      //result == true click en continuar o en la x || undefined = click fuera del modal
      //GESTIONES DEL MODAL DESPUES DE SU CIERRE
    });
  }


  /*METODOS LOGIN*/

  // Método que comprueba si el usuario tiene sesión
  public isAuthenticated(): boolean {
    return this.getUserSession() != null && this.timeExpired() ? true : false;
  }

  // Obtención de la sesión del usuario
  public getUserSession() {
    const userTokenSesion = sessionStorage.getItem('userToken') ? sessionStorage.getItem('userToken') : null;
    return userTokenSesion;
  }

  public timeExpired(): boolean {
    let isTime;
    const actuDate2 = Math.floor(new Date().getTime() / 1000);
    const saveDate = +localStorage.getItem(sessionStorage.getItem('userToken') + 'TimeExpired');
    actuDate2 < saveDate ? (isTime = true) : (isTime = false);
    if (isTime) {
      let initialDate = new Date().getTime();
      initialDate += 1000 * 60 * 30;
      localStorage.setItem(sessionStorage.getItem('userToken') + 'TimeExpired', JSON.stringify(initialDate));
      return isTime;
    } else {
      this.loginService.setLogoutSession();
      return isTime;
    }
  }



  /*FIN METODOS LOGIN*/
  consultarToken(obj: Login) {
    this.spinner.show();
    this.loginService.postUsuario(obj).subscribe(
      (response: HttpResponse<any>) => {
        const token = response.headers.get('Authorization');
        this.responseSession = jwt_decode(token);
        if (this.responseSession != '') {
          this.loginService.setAuthToken(token);
          this.loginService.setUserSession(this.responseSession, token, this.responseSession.exp);
          
          // this.nombreUsuario = this.obtenerNombreUsuarioTrasLogueo();
          // this.headerNavService.valorNombreUsuario(this.nombreUsuario);
          // this.toastr.success('Operación exitosa', 'Inicio de sesión correcto');
          setTimeout(() => {
            this.router.navigate(['facturas']);
          }, 500);
          setTimeout(() => {
            this.spinner.hide();
          }, 1500);

        }
      },
      err => {
        console.error("Ocurrió un error: " + err.message);
        let errorCode = err.status;
        // let mensaje;
        switch (errorCode) {
          case 401:
            // mensaje = "Email y/o contraseña incorrectos"
            this.translateService.stream("MENSAJES.DATOSINCORRECTOS").subscribe(resul => {
              this.textoErrorLogin = resul
              // mensaje = resul              
            });
            break;
          case 500:
            this.translateService.stream("MENSAJES.ERRORSERVER").subscribe(resul => {
              this.textoErrorLogin = resul
              // mensaje = resul
            });
            break;
          case 0: //problema de conexion con el backEnd
            this.translateService.stream("MENSAJES.ERRORINTENTARDENUEVO").subscribe(resul => {
              this.textoErrorLogin = resul
              // mensaje = resul
            });
            break;
        }
        setTimeout(() => {
          this.spinner.hide();
          // if(errorCode == 401){
          //   this.toastr.error('Por favor, revise.', mensaje);
          // } else{
          //   this.toastr.error( mensaje);
          // }
          // this.textoErrorLogin = mensaje;
          this.errorLogin = true;
          this.passLogin = '';
          this.mailLogin = '';
        }, 1500);
      }
    );
  }




  obtenerNombreUsuarioTrasLogueo(): string {
    let nombreUsuario;
    this.userService.getUsuarioDetails().subscribe(
      (response: any) => {
        if (response && response.records && response.records[0]) {
          nombreUsuario = response.records[0].name;
        }
      },
      err => {
        console.error("Ocurrió un error: " + err.message);
      }
    );
    return nombreUsuario;
  }

  procederLogin() {
    this.errorLogin = false;
    // this.spinner.show();
    if (this.utilsService.isNotNullOrNotEmptyOrNotUndefined(this.mailLogin) && this.utilsService.isNotNullOrNotEmptyOrNotUndefined(this.passLogin)) {
      if (!this.isAuthenticated()) {
        this.mailLogin = this.mailLogin.toLowerCase();
        let objLogin: Login = { username: this.mailLogin, password: this.passLogin };
        this.consultarToken(objLogin);
      }
      //if user is authenticated on init, url go to facturas
    } else {
      // setTimeout(() => {
      // this.spinner.hide();
      this.translateService.stream("MENSAJES.FALTANCAMPOS").subscribe(resul => {
        this.textoErrorLogin = resul
      });
      this.errorLogin = true;
      // this.toastr.error('Por favor, revise.', 'Existen campos sin completar');
      // }, 1500);
    }
  }


  validateUserNameAndContract(usuario: Usuario) {
    this.spinner.show();
    this.userService.validateNewUserData(usuario).subscribe(
      (response: any) => {
        setTimeout(() => {
          this.spinner.hide();
          this.procesoRegistroCorrecto = true;
        }, 500);

      },
      err => {
        console.error("Ocurrió un error: " + err.message);
        let errorCode = err.status;
        // let mensaje;
        switch (errorCode) {
          case 409:
            this.translateService.stream("MENSAJES.USUARIOEXISTEDNI").subscribe(resul => {
              this.textoError = resul;
              // mensaje = resul
            });
            break;
          case 404:
            //  mensaje = "El DNI / NIF no es válido";
            this.translateService.stream("MENSAJES.DATOSINCORRECTOS").subscribe(resul => {
              this.textoError = resul;
              // mensaje = resul
            });
            break;
          case 500:
            this.translateService.stream("MENSAJES.ERRORSERVER").subscribe(resul => {
              this.textoError = resul;
              // mensaje = resul
            });
            break;
          case 0: //problema de conexion con el backEnd
            this.translateService.stream("MENSAJES.ERRORINTENTARDENUEVO").subscribe(resul => {
              this.textoError = resul;
              // mensaje = resul
            });
            break;
        }
        this.dni = '';
        this.numContrato = '';
        setTimeout(() => {
          this.spinner.hide();
          // this.textoError = mensaje;
          this.error = true;
          // if (errorCode == 409 || errorCode == 404){
          //   this.toastr.error('Por favor, revise.', mensaje);
          // }else{
          //   this.toastr.error(mensaje);
          // }
        }, 500);
      }
    );
  }

  validateUserEmail(usuario: Usuario) {
    this.spinner.show();
    this.userService.validateNewUserData(usuario).subscribe(
      (response: any) => {
        setTimeout(() => {
          this.spinner.hide();
          this.registroMailCorrecto = true;
        }, 500);
      },
      err => {
        console.error("Ocurrió un error: " + err.message);
        let errorCode = err.status;
        // let mensaje;
        switch (errorCode) {
          case 404:
            this.translateService.stream("MENSAJES.MAILNOASOCIADO").subscribe(resul => {
              this.textoErrorMail = resul;
              // mensaje = resul
            });
            break;
          case 500:
            this.translateService.stream("MENSAJES.ERRORSERVER").subscribe(resul => {
              this.textoErrorMail = resul;
              // mensaje = resul
            });
            break;
          case 0: //problema de conexion con el backEnd
            this.translateService.stream("MENSAJES.ERRORINTENTARDENUEVO").subscribe(resul => {
              this.textoErrorMail = resul;
              // mensaje = resul
            });
            break;
        }
        this.email = '';
        setTimeout(() => {
          this.spinner.hide();
          this.errorMail = true;
          // this.textoErrorMail = mensaje;
          // if (errorCode == 404){
          //   this.toastr.error('Por favor, revise.', mensaje);
          // }else{
          //   this.toastr.error(mensaje);
          // }
        }, 500);
      }
    );
  }

  procederRegistro() {
    this.error = false;
    if (this.utilsService.isNotNullOrNotEmptyOrNotUndefined(this.dni) && this.utilsService.isNotNullOrNotEmptyOrNotUndefined(this.numContrato)) {
      // if (this.utilsService.esDniValido(this.dni)) {
      let userToValidate: Usuario = {
        id: this.dni, password: null, role: null, name: null, surname: null, email: null, contractNumber: this.numContrato,
        phoneNumber: null, totalSize: null, oldPassword: null
      }
      this.validateUserNameAndContract(userToValidate);

      // this.procesoRegistroCorrecto = true;
      // } 
      // else {
      //   // this.toastr.error('Por favor, revise.', 'El DNI / NIF introducido no es válido');
      //   this.textoError = "El DNI / NIF introducido no tiene el formato esperado.";
      //   this.error = true;
      //   this.dni = '';
      //   // this.numContrato = '';
      // }
    } else {
      // this.toastr.error('Por favor, revise.', 'Existen campos sin completar');
      this.translateService.stream("MENSAJES.FALTANCAMPOS").subscribe(resul => {
        this.textoError = resul
      });
      this.error = true;
    }
  }


  redirigirHome() {
    this.router.navigate(['login']);
  }

  enviarEmail() {
    this.error = false;
    // this.spinner.show();
    if (this.utilsService.isNotNullOrNotEmptyOrNotUndefined(this.emailOlvidePassword)) {
      if (this.utilsService.esCorreoElectronico(this.emailOlvidePassword)) {
        this.spinner.show();

        // let userToChangePassword: Usuario = {
        //   id: null, password: null, role: null, name: null, surname: null, email: this.emailOlvidePassword, contractNumber: null,
        //   phoneNumber: null, totalSize: null, oldPassword: null
        // }
        this.emailOlvidePassword = this.emailOlvidePassword.toLowerCase();
        this.userService.forgotPassword(this.emailOlvidePassword, this.translateService.currentLang).subscribe(
          (response: any) => {
            setTimeout(() => {
              this.spinner.hide();
              // let mensaje
              this.translateService.stream("MENSAJES.CORREOENVIADO").subscribe(resul => {
                this.mensajeToast1 = resul
                // mensaje = resul
              });
              this.toastr.success('', this.mensajeToast1, {
                disableTimeOut: true,
                // positionClass: 'toast-top-center',
              });
              this.emailOlvidePassword = '';
              this.mostrarLogin = true;
            }, 500);
          },
          err => {
            console.error("Ocurrió un error: " + err.message);
            let errorCode = err.status;
            // let mensaje;
            switch (errorCode) {
              case 404:
                //  mensaje = "El DNI / NIF no es válido";
                this.translateService.stream("MENSAJES.MAILNOASOCIADO").subscribe(resul => {
                  this.textoError = resul
                  // mensaje = resul
                });
                break;
              case 500:
                this.translateService.stream("MENSAJES.ERRORSERVER").subscribe(resul => {
                  this.textoError = resul
                  // mensaje = resul
                });
                break;
              case 0: //problema de conexion con el backEnd
                this.translateService.stream("MENSAJES.ERRORINTENTARDENUEVO").subscribe(resul => {
                  this.textoError = resul
                  // mensaje = resul
                });
                break;
            }
            setTimeout(() => {
              this.spinner.hide();
              if (errorCode == 404) {
                this.emailOlvidePassword = '';
              }
              this.error = true;
              // this.textoError = mensaje;
            }, 500);
          });
      } else {
        // setTimeout(() => {
        // this.spinner.hide();
        this.emailOlvidePassword = '';
        this.error = true;
        this.translateService.stream("MENSAJES.FORMATOMAILINCORRECTO").subscribe(resul => {
          this.textoError = resul
        });
        // }, 1500);
      }
    } else {
      // setTimeout(() => {
      // this.spinner.hide();
      this.error = true;
      this.translateService.stream("MENSAJES.FALTANCAMPOS").subscribe(resul => {
        this.textoError = resul
      });
      // }, 1500);
    }
  }


  registrarMail() {
    this.errorMail = false;
    // this.spinner.show();
    if (this.utilsService.isNotNullOrNotEmptyOrNotUndefined(this.email)) {
      if (this.utilsService.esCorreoElectronico(this.email)) {

        this.email = this.email.toLowerCase();

        let userToValidate: Usuario = {
          id: this.dni, password: null, role: null, name: null, surname: null, email: this.email, contractNumber: this.numContrato,
          phoneNumber: null, totalSize: null, oldPassword: null
        }

        this.validateUserEmail(userToValidate);

      } else {
        // this.spinner.hide();
        this.email = '';
        this.errorMail = true;
        this.translateService.stream("MENSAJES.FORMATOMAILINCORRECTO").subscribe(resul => {
          this.textoErrorMail = resul
        });
        // this.toastr.error('Por favor, revise.', 'El e-mail introducido no es válido');
      }
    } else {
      // this.spinner.hide();
      this.errorMail = true;
      this.translateService.stream("MENSAJES.FALTANCAMPOS").subscribe(resul => {
        this.textoErrorMail = resul
      });
    }
  }

  registrarPassword() {
    this.errorPassword = false;
    if (this.utilsService.isNotNullOrNotEmptyOrNotUndefined(this.password) && this.utilsService.isNotNullOrNotEmptyOrNotUndefined(this.repeticionPassword)) {
      if (this.password !== this.repeticionPassword) {
        this.password = '';
        this.repeticionPassword = '';
        this.translateService.stream("MENSAJES.DATOSINCORRECTOS").subscribe(resul => {
          this.textoErrorPassword = resul
        });
        this.errorPassword = true;
        // this.toastr.error('Por favor, revise.', 'Las contraseñas no coinciden');
      } else {
        if (this.utilsService.requisitosSeguridadPassword(this.password)) {
          // this.abrirModal('finRegistro');
          this.email = this.email.toLowerCase();
          let usuarioToCreate = {
            id: this.dni, password: this.password, role: null, name: null, surname: null, email: this.email, contractNumber: this.numContrato,
            phoneNumber: null, totalSize: null, oldPassword: null, language: this.translateService.currentLang
          };
          this.crearUsuario(usuarioToCreate);
        } else {
          this.translateService.stream("MENSAJES.DATOSINCORRECTOS").subscribe(resul => {
            this.textoErrorPassword = resul
          });
          this.errorPassword = true;
          // this.toastr.error('Por favor, revise.', 'La contraseña no cumple los requisitos de seguridad');
          this.password = '';
          this.repeticionPassword = '';
        }
      }
    } else {
      // this.toastr.error('Por favor, revise.', 'Existen campos sin completar');
      this.translateService.stream("MENSAJES.FALTANCAMPOS").subscribe(resul => {
        this.textoErrorPassword = resul
      });
      this.errorPassword = true;
    }
  }

  crearUsuario(usuario: Usuario) {
    this.userService.createUsuario(usuario).subscribe(
      (response: any) => {
        console.log(response);
        this.abrirModal(this.tipoPantalla, this.textoConfirmacionORecuperacion, this.textoConfirmacionORecuperacionSmall);
        this.password = '';
        this.repeticionPassword = '';
        this.registroMailCorrecto = false;
        this.procesoRegistroCorrecto = false;
        this.dni = '';
        this.password = '';
        this.email = '';
        this.numContrato = '';
        this.repeticionPassword = '';
      },
      err => {
        console.error("Ocurrió un error: " + err.message);
        let errorCode = err.status;
        // let mensaje;
        switch (errorCode) {
          case 409:
            this.translateService.stream("MENSAJES.DNIREGISTRADO").subscribe(resul => {
              // mensaje = resul
              this.mensajeToast2 = resul
            });
            break;
          case 404:
            this.translateService.stream("MENSAJES.DNIINVALIDO").subscribe(resul => {
              // mensaje = resul
              this.mensajeToast2 = resul
            });
            break;
          case 500:
            this.translateService.stream("MENSAJES.ERRORSERVER").subscribe(resul => {
              // mensaje = resul
              this.mensajeToast2 = resul
            });
            break;
          case 0: //problema de conexion con el backEnd
            this.translateService.stream("MENSAJES.ERRORINTENTARDENUEVO").subscribe(resul => {
              // mensaje = resul
              this.mensajeToast2 = resul
            });
            break;
        }
        setTimeout(() => {
          this.spinner.hide();
          if (errorCode == 409 || errorCode == 404) {
            // let mensaje
            this.translateService.stream("MENSAJES.REVISE").subscribe(resul => {
              this.mensajeToast2 = resul
            });
            this.toastr.error(this.mensajeToast2, this.mensajeToast2);
          } else {
            this.toastr.error(this.mensajeToast2);
          }
        }, 1500);
      }
    );

  }

  cambiarIcono(evento, inputId, iconId) {
    if (evento) {
      let tipoInput = $("#" + inputId).attr("type");
      if (tipoInput == "password") {
        $("#" + inputId).prop("type", "text");
        $("#" + iconId).attr("class", "fa fa-eye");
      } else {
        $("#" + inputId).prop("type", "password");
        $("#" + iconId).attr("class", "fa fa-eye-slash");
      }
    }
  }



  ngOnDestroy(): void {
    this.headerNavService.visibilidadMenu(true);
    // this.headerNavService.visibilidadDirUsuario(true);
    this.headerNavService.visibilidadUsuarioLogueado(true);
    // this.footerService.visibilidadFooter(true);
    this.headerNavService.visibilidadHeader(true);

  }


}


/*SELECTOR PARA LA CLASE MODAL*/

@Component({
  selector: 'modalLogin',
  templateUrl: './modalLogin.html',
  styleUrls: ['./modalLogin.scss'],
  encapsulation: ViewEncapsulation.None
  //quitamos la encapsulación para dejar el resto de modales obtengan su conf de styles.css generico y sobreescribir las propiedades desde este componente
})
export class dialogModalLogin {

  tipoVentanaModal: string;
  simbolosEspeciales: string = '¡!$?&#@/\()=¿?*[];,:._<>+-';
  tituloModal: string;
  textoModal: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private toastr: ToastrService, private utilsService: UtilsService, private loginService: LoginService) { }


  ngOnInit(): void {
    if (this.data && this.data.info) {
      this.tipoVentanaModal = this.data.info;
    }
    if (this.data && this.data.titulo && this.data.texto) {
      this.tituloModal = this.data.titulo;
      this.textoModal = this.data.texto;
    }
  }

  iniciarSesion() {
    this.loginService.navegarLogin(true);
  }

}


