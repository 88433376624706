import {  Component, OnInit } from '@angular/core';
import { FooterService } from 'src/app/Services/footerService';

declare var $;
@Component({
  selector: 'footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  fechaActual : any;
  mostrarFooter: boolean = true;
  constructor(private footerService:FooterService) { }

  ngOnInit(): void {
    this.fechaActual = new Date().getFullYear();

    this.footerService.mostrarFooter$.subscribe(filtroServiceBoolean => {
      this.mostrarFooter = filtroServiceBoolean;
    });

  }

}
  
