<button mat-icon-button class="close-button" [mat-dialog-close]="true">
    <mat-icon class="close-icon" >close</mat-icon>
  </button>


<h2 style="color:black;" mat-dialog-title *ngIf="nodoVentanaModal != undefined && nodoVentanaModal == 'telefono'">{{"MODALVERIFICACION.VERIFICACION" | translate}}</h2>
<div mat-dialog-content *ngIf="nodoVentanaModal != undefined && nodoVentanaModal == 'telefono'">
  <p>
    {{"MODALVERIFICACION.CAMBIOTELEFONO1" | translate}} <span style="color:#2e1cf7;">900 293 741</span> {{"MODALVERIFICACION.CAMBIOTELEFONO2" | translate}}
  </p>
</div>

<!-- <ng-template #email> -->
<h2 style="color:black;" mat-dialog-title *ngIf="nodoVentanaModal != undefined && nodoVentanaModal == 'email'" >{{"MODALVERIFICACION.SEGUROCAMBIOMAIL" | translate}}</h2>
<div mat-dialog-content *ngIf="nodoVentanaModal != undefined && nodoVentanaModal == 'email'">
  <p>
    {{"MODALVERIFICACION.RECUERDACAMBIOMAIL" | translate}}
  </p> 
  </div>
<!-- </ng-template> -->

<!-- <ng-template #password> -->
<h2 style="color:black;" mat-dialog-title *ngIf="nodoVentanaModal != undefined && nodoVentanaModal == 'password'" >{{"MODALVERIFICACION.SEGUROCAMBIOPASSWORD" | translate}}</h2>
<div mat-dialog-content *ngIf="nodoVentanaModal != undefined && nodoVentanaModal == 'password'">
  <p class="parrafoModal">
    {{"MODALVERIFICACION.PASSWORDELEJIDA" | translate}}
</p>
</div>
<!-- </ng-template> -->



<!-- <i class="fa fa-user" id="iconUser"></i> -->
<h2 style="color:black;" mat-dialog-title *ngIf="nodoVentanaModal != undefined && nodoVentanaModal == 'requisitosPassword'">{{"MODALVERIFICACION.COMOPASSWORD" | translate}}</h2>
<div mat-dialog-content *ngIf="nodoVentanaModal != undefined && nodoVentanaModal == 'requisitosPassword'">
<p class="parrafoModal">
  {{"MODALVERIFICACION.FORMATOPASSWORD" | translate}}
</p>
  <ul id="listaModal">
    <li>{{"MODALVERIFICACION.MAYUSCULA" | translate}}</li>
    <li>{{"MODALVERIFICACION.MINUSCULA" | translate}}</li>
    <li>{{"MODALVERIFICACION.NUMERO" | translate}}</li>
    <li>{{"MODALVERIFICACION.SIMBOLO" | translate}} {{simbolosEspeciales}}</li>
    <br>
    <li>{{"MODALVERIFICACION.EJEMPLO" | translate}}</li>
  </ul>
</div>




<div mat-dialog-actions>
    <button type="button" class="btn btn-warning btn-lg posicionamientoBtn" style="margin-top:.75em;margin-bottom:.5em;width:100%;font-weight: bold;"  [mat-dialog-close] = "true" (click)="confirmarCambioPassword()">{{"GLOBAL.CONTINUAR" | translate}}</button> <!--(click)="guardarOperacion()"-->
    <button type="button" *ngIf="nodoVentanaModal != undefined && nodoVentanaModal != 'requisitosPassword'" class="btn btn-secondary btn-lg posicionamientoBtn" style="margin-top:.75em;margin-bottom:.5em;width:100%;font-weight: bold;"  [mat-dialog-close] = "true">{{"GLOBAL.CANCELAR" | translate}}</button> 
  </div>

