
<div class="row col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 noPadding" id="principal" >
    <div class=" col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12  menuBackground ">
        <div class="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 mx-auto" id="divPaddingFacturas" style="margin-top:1.5em;">
            <h2>{{'FACTURAS.FACTURAS' | translate }}</h2>
            <!-- <div>
              <span style="color:white">{{direccionUsuario}}</span><span style="color:lightgray"> {{comunidadUsuario}}</span> 
          </div> -->
        </div>

        <div class ="row col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 mx-auto borderBottom" style="background-color: white;margin-top:1em;" [ngStyle]="{'margin-bottom': facturas != null && facturas.length == 0 ? '2em' : '0' }" >
          <div class="row col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10" style="margin-top: 2em;">
            <!-- <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <h6 style="color:gray; margin-right: 40px;">Consulta tus facturas</h6>
            </div> -->
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <h4 style="color:black">{{'FACTURAS.HISTORICO' | translate }}</h4>
            </div>
          </div>
          <div class="row col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 noPadding " *ngIf="existenFacturas"> 
            <div class="col-12 col-md-2 offset-md-10 col sm-12 col-lg-12 col-xl-12  noPadding">
              <div class="d-flex flex-row-reverse bd-highlight mb-2">
                <div class="p-2 bd-highlight" style="margin-top:1.6em;" class="divSelectPagination">
                  <select (change)="onTableSizeChange($event)" class="custom-select">
                    <option *ngFor="let size of tableSizes" [value]="size">
                      {{ size }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          
          </div>
         
          <div class="table-responsive" id="tablaFacturas" *ngIf="existenFacturas;else noExistenFacturas">
            <table class="table" style="white-space: nowrap;" > <!--style="text-align: center;"-->
                <thead>
                  <tr>
                    <th scope="col">{{"FACTURAS.PERIODO" | translate}}</th>
                    <th scope="col">{{"FACTURAS.NUMERO" | translate}}</th>
                    <th scope="col">{{"FACTURAS.CUPS" | translate}}</th>
                    <th scope="col">{{"FACTURAS.ESTADO" | translate}}</th>
                    <th scope="col">{{"FACTURAS.IMPORTE" | translate}}</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let col of facturas |  paginate : {
                    itemsPerPage: tableSize,
                    currentPage: page,
                    totalItems: count
                    };" >
                    <td>{{col.startDate | date: translateService.currentLang == 'eu' ? 'yyyy/MM/dd' : 'dd/MM/yyyy'}} - {{col.endDate | date: translateService.currentLang == 'eu' ? 'yyyy/MM/dd' : 'dd/MM/yyyy'}}</td>
                    <td  width="15%">{{col.name}}</td>
                    <td>{{col.servicePointNumber}}</td>
                    <!--, 'padding-left': col.paid === 'Si' ? '' : '28px'-->
                    <td  id="statusFactura" [ngStyle]="{'color': col.paid === 'Si' ? '#00760c' : '#b6720f' }" ><i class="fa fa-stop" [ngStyle]="{'margin-right': col.paid === 'Si' ? '5px' : '4.75px'}"></i>&nbsp;&nbsp;{{col.paid === 'Si' ? ('FACTURAS.PAGADA' | translate): ('FACTURAS.PENDIENTE' | translate)}}</td>
                    <td *ngIf="col.total != null; else importeNulo" style="text-align: right;">{{col.total | number : '1.2-2'}}€</td>
                    <ng-template #importeNulo>
                    <td style="text-align: right;"></td>
                    </ng-template>
                    <td><a  class="enlaceFactura" (click)="descargaFactura(col.invoiceUrl, col.name, col.startDate, col.endDate)">{{'FACTURAS.DESCARGARFACTURA' | translate }}</a></td>
                  </tr>
                  <!-- <small>Mostrando {{tableSize}} de {{count}} registros</small> -->
                </tbody>
              </table>
              </div>
        </div>
        <ng-template #noExistenFacturas>
          <div class="col-12 col-md-12 col-xl-12 col-lg-12" style="text-align: center;margin-bottom: 5em;margin-top: 2em;">
            <h5 style="color:gray;">{{'FACTURAS.SINFACTURAS' | translate }}</h5>
          </div>
        </ng-template>
        <div class="d-flex justify-content-center mx-auto" style="margin-top:.75em;margin-bottom: 1.5em;" *ngIf="existenFacturas">
          <pagination-controls 
            responsive="true" 
            [previousLabel]="'GLOBAL.ANTERIOR' | translate" 
            [nextLabel]="'GLOBAL.SIGUIENTE' | translate" 
            (pageChange)="onTableDataChange($event)">
          </pagination-controls>
        </div>
        </div>
</div>

<!-- "{{'GLOBAL.SIGUIENTE' | translate }}"  -->
<!-- {{'GLOBAL.ANTERIOR' | translate }} -->
