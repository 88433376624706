
<div class="row col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 noPadding" id="principalContratos" >
    <div class=" col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12  menuBackground ">
        <div class="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 mx-auto" id="divPaddingContratos" style="margin-top:1.5em;">
            <h2>{{'CONTRATOS.CONTRATOS' | translate }}</h2>
            <!-- <div>
              <span style="color:white">{{direccionUsuario}}</span><span style="color:lightgray"> {{comunidadUsuario}}</span> 
          </div> -->
        </div>

        <div class ="row col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 mx-auto borderBottom" style="background-color: white;margin-top:1em;" [ngStyle]="{'margin-bottom': contratos != undefined && contratos.length == 0 ? '2em' : '0' }" >
          <div class="row col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10" style="margin-top: 2em;">
            <!-- <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <h6 style="color:gray; margin-right: 40px;">Consulta tus contratos</h6>
            </div> -->
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <h4 style="color:black">{{'CONTRATOS.HISTORICO' | translate }}</h4>
            </div>
          </div>

          <div class="row col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 noPadding " *ngIf="existenContratos"> 
            <div class="col-12 col-md-2 offset-md-10 col sm-12 col-lg-12 col-xl-12  noPadding">
              <div class="d-flex flex-row-reverse bd-highlight mb-2">
                <div class="p-2 bd-highlight" style="margin-top:1.6em;" class="divSelectPagination">
                  <select (change)="onTableSizeChange($event)" class="custom-select">
                    <option *ngFor="let size of tableSizes" [value]="size">
                      {{ size }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          
          </div>
         
          
          <div class="table-responsive" id="tablaContratos" *ngIf="existenContratos;else noExistenContratos" >
            <table class="table" style="white-space: nowrap;" > <!--style="text-align: center;"-->
                <thead>
                  <tr>
                    <th scope="col">{{"CONTRATOS.NUMERO" | translate}}</th>
                    <th scope="col">{{"CONTRATOS.DIRECCION" | translate}}</th>
                    <th scope="col">{{"CONTRATOS.CUPS" | translate}}</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let col of contratos |  paginate : {
                    itemsPerPage: tableSize,
                    currentPage: page,
                    totalItems: count
                  };" >
                    <td>{{col.contractNumber}}</td>
                    <td>{{col.address.name}} {{col.address.number}} {{col.address.floor}}º{{col.address.letter}}, {{col.address.city}} </td>
                    <!-- <td style="font-weight: bold;" [ngStyle]="{'color': col.ESTADO === 'Pendiente' ? '#b6720f' : '#00760c', 'padding-left': col.ESTADO != 'Pendiente' ? '2px' : ''}" ><i class="fa fa-stop" [ngStyle]="{'margin-right': col.ESTADO === 'Pendiente' ? '' : '8px'}"></i>&nbsp;&nbsp;{{col.ESTADO}}</td> -->
                    <td>{{col.servicePointNumber}}</td>
                    <td><a  class="enlaceContrato" (click)="descargarContrato(col.url,col.contractNumber)">{{'CONTRATOS.DESCARGARCONTRATO' | translate }}</a></td>
                  </tr>
                  <!-- <small>Mostrando {{tableSize}} de {{count}} registros</small> -->
                </tbody>
              </table>
              </div>
        </div>
        <ng-template #noExistenContratos>
          <div class="col-12 col-md-12 col-xl-12 col-lg-12" style="text-align: center;margin-bottom: 5em;margin-top: 2em;">
            <h5 style="color:gray;">{{'CONTRATOS.SINCONTRATOS' | translate }}</h5>
          </div>
        </ng-template>
        <div class="d-flex justify-content-center mx-auto" style="margin-top:.75em;margin-bottom: 1.5em;" *ngIf="existenContratos">
          <pagination-controls 
            responsive="true" 
            [previousLabel]="'GLOBAL.ANTERIOR' | translate" 
            [nextLabel]="'GLOBAL.SIGUIENTE' | translate" 
            (pageChange)="onTableDataChange($event)">
          </pagination-controls>
        </div>
        </div>
   

</div>


<!--
    BACK UP CONTRATOS PREVIO AL LISTADO

<div class="row col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 noPadding " id="contenedor">
    <div class=" col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12  menuBackground ">
            <div class="col-8 col-sm-8 col-md-8 col-lg-8 col-xl-8 mx-auto" style="margin-top:1.5em;">
                <h2>Mi Contrato</h2>
                <div>
                    <span style="color:white">{{direccionUsuario}}</span><span style="color:lightgray"> {{comunidadUsuario}}</span> 
                </div>
            </div>
            
        <div class ="row col-8 col-sm-8 col-md-8 col-lg-8 col-xl-8 mx-auto borderBottom" style="background-color: white;padding:15px;margin-top:1em;">
            <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4" style="margin-top: .75em;">
                <span style="color:gray">Nº contrato</span>
                <h5 style="color:black">EKI24842LLE</h5>
            </div>
            <div class="col-8 col-sm-8 col-md-8 col-lg-8 col-xl-8" style="padding:15px">
                <button type="button" class="btn btn-warning btn-lg right bigBtn">Ver Contrato <i class="fa fa-arrow-right" style="margin-left: 0.15em;"></i></button>
            </div>
            <div class="row col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
                <div class=" col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 borderTop" style="margin-left:14px; padding-left:2.5px;display: inherit;">
                    <div style="margin-top:1em;padding-left: 0 !important;" class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                        <span style="color:gray">Dirección de suministro</span>
                        <h5 style="color:black">DIRECCION INVENTADA</h5>
                    </div>
                    <div style="margin-top:1em;" class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                        <span style="color:gray">CLIPS</span>
                        <h5 style="color:black">ES123456789</h5>
                    </div>
                </div>
            
            
            </div>
            </div>
            </div>
            </div>
-->

